import React from "react";
import { Row, Col } from "react-bootstrap";
import { ListGroup } from "react-bootstrap";
import { useFormik } from "formik";
import * as Yup from "yup";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@mui/material/MenuItem";
import Grid from "@mui/material/Grid";
import "./index.css";
import GstForm from "./GstForm";

function Subscription(props) {
  React.useEffect(() => {
    document.body.scrollTop = 0;
  }, []);
  const validationSchema = Yup.object().shape({
    storeStatus: Yup.string()
    // .required("Please Enter Store Status")
    ,
    storeLocality: Yup.string().required("Please Enter Store Locality")
    ,
    productMarkupPercent: Yup.number()
      .typeError("Must be a number"),
      // .required("Enter Product Markup Percent"),
    packingMarkupPercent: Yup.number()
      .typeError("Must be a number")
      // .required("Enter Packing Markup Percent")
      ,
    deliveryChargeMarkupPercent: Yup.number()
      .typeError("Must be a number")
      // .required("Enter Delivery Charge Markup Percent")
      ,
    platformFeePercent: Yup.number()
      .typeError("Must be a number")
      // .required("Enter Platform Fee Percent")
      ,
    productGSTPercent: Yup.number()
      .typeError("Must be a number")
      // .required("Enter Product GST Percent")
      ,
    packingGSTPercent: Yup.number()
      .typeError("Must be a number")
      // .required("Enter Packing GST Percent")
      ,
    deliveryChargeGSTPercent: Yup.number()
      .typeError("Must be a number")
      // .required("Enter Delivery Charge GST Percent")
      ,
    platformFeeGSTPercent: Yup.number()
      .typeError("Must be a number")
      // .required("Enter Platform Fee GST Percent")
      ,
  });

  const formik = useFormik({
    initialValues: {
      storeStatus: props.searchDetails.store.storeStatus || "",
      storeLocality: props.searchDetails.store.storeLocality || "",
      productMarkupPercent:
        props.searchDetails.store.productMarkupPercent || "",
      packingMarkupPercent:
        props.searchDetails.store.packingMarkupPercent || "",
      deliveryChargeMarkupPercent:
        props.searchDetails.store.deliveryChargeMarkupPercent || "",
      platformFeePercent: props.searchDetails.store.platformFeePercent || "",
      productGSTPercent: props.searchDetails.store.productGSTPercent || "",
      packingGSTPercent: props.searchDetails.store.packingGSTPercent || "",
      deliveryChargeGSTPercent:
        props.searchDetails.store.deliveryChargeGSTPercent || "",
      platformFeeGSTPercent:
        props.searchDetails.store.platformFeeGSTPercent || "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      props.saveDetails({
        ...props.searchDetails.store,
        ...values,
      });
      props.next();
    },
  });


  return (
    <>
      <h4
        style={{
          paddingTop: "30px",
          fontWeight: 500,
          textAlign: "center",
          color: "black",
          textDecoration: "underline",
        }}
      >
        {"Payment Details"}
      </h4>
      <form
        onSubmit={formik.handleSubmit}
        className="sub-form"
        style={{ marginTop: "30px" }}
      >
        {/* <div className="sub-form1" id="F1">
          <ListGroup className="list">
            <ListGroup.Item
              className="list"
              style={{
                width: "76%",
                height: 110,
                textAlign: "left",
                margin: "0 40px 0 80px",
                paddingLeft: "25px",
              }}
            >
              <h4> Basic Type</h4>
              <br />
              <Row>
                <Col md={6}>Free for 6 months</Col>
              </Row>
            </ListGroup.Item>
          </ListGroup>
        </div> */}

        <Grid
          container
          spacing={2}
          style={{
            paddingTop: "15px",
            paddingBottom: "20px",
            paddingLeft: "13px",
            width: "100%",
          }}
        >
          <Grid item xs={4}>
            <label>Store status</label>
          </Grid>
          <Grid item xs={6} style={{ height: 35, width: "100%" }}>
            <TextField
              fullWidth
              InputProps={{ style: { height: 35, width: "100%" } }}
              select
              variant="outlined"
              name="storeStatus"
              value={formik.values.storeStatus}
              onChange={formik.handleChange}
              error={
                formik.touched.storeStatus && Boolean(formik.errors.storeStatus)
              }
              helperText={
                formik.touched.storeStatus && formik.errors.storeStatus
              }
            >
              <MenuItem value={"submitted"}>Submitted</MenuItem>
              <MenuItem value={"active"}>Active</MenuItem>
              <MenuItem value={"inactive"}>Inactive</MenuItem>
              <MenuItem value={"banned"}>Banned</MenuItem>
              <MenuItem value={"reject"}>Reject</MenuItem>
            </TextField>
          </Grid>

          {/* Additional fields start here */}
          <Grid item xs={4}>
            <label>Store Locality</label>
          </Grid>
          <Grid item xs={6}>
            <TextField
              fullWidth
              InputProps={{ style: { height: 35, width: "100%" } }}
              variant="outlined"
              name="storeLocality"
              value={formik.values.storeLocality}
              onChange={formik.handleChange}
              error={
                formik.touched.storeLocality &&
                Boolean(formik.errors.storeLocality)
              }
              helperText={
                formik.touched.storeLocality && formik.errors.storeLocality
              }
            />
          </Grid>

          {[
            { field: "productMarkupPercent", label: "Product Markup Percent" },
            { field: "packingMarkupPercent", label: "Packing Markup Percent" },
            {
              field: "deliveryChargeMarkupPercent",
              label: "Delivery Charge Markup Percent",
            },
            { field: "platformFeePercent", label: "Platform Fee Percent" },
            { field: "productGSTPercent", label: "Product GST Percent" },
            { field: "packingGSTPercent", label: "Packing GST Percent" },
            {
              field: "deliveryChargeGSTPercent",
              label: "Delivery Charge GST Percent",
            },
            {
              field: "platformFeeGSTPercent",
              label: "PlatformFee GST Percent",
            },
          ].map(({ field, label }) => (
            <React.Fragment key={field}>
              <Grid item xs={4}>
                <label>{label}</label>
              </Grid>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  variant="outlined"
                  InputProps={{ style: { height: 35, width: "100%" } }}
                  name={field}
                  type="number"
                  value={formik.values[field]}
                  onChange={formik.handleChange}
                  error={formik.touched[field] && Boolean(formik.errors[field])}
                  helperText={formik.touched[field] && formik.errors[field]}
                />
              </Grid>
            </React.Fragment>
          ))}
        </Grid>

        <div className="buttons">
          <button type="button" onClick={props.back} className="nextbutton">
            Back
          </button>
          <button type="submit" className="nextbutton">
            {"Save & Next"}
          </button>
        </div>
      </form>
    </>
  );
}

export default Subscription;
