import { gql } from "@apollo/client";

export const NOTIFICATION_STATUS = gql`
  mutation (
    $storeid: ID!
    $orderid: ID!
    $status: String!
    $deliveryDate: String!
    $deliveryTime: String!
    $orderNumber: Int!
    $shopassistantId: ID
  ) {
    scheduleAlertOnStatus(
      storeid: $storeid
      orderid: $orderid
      status: $status
      deliveryDate: $deliveryDate
      deliveryTime: $deliveryTime
      orderNumber: $orderNumber
      shopassistantId: $shopassistantId
    )
  }
`;

export const CHANGE_TO_ACTIVE_STATUS = gql`
  mutation ($orderid: ID!, $preparationTime: Int!) {
    changeOrderStatusToShopping(
      orderid: $orderid
      preparationTime: $preparationTime
    ) {
      id
      orderNumber
      storeid {
        id
        storeName
        deliveryMaxDistance
        storeCoordinates {
          coordinates
        }
        storeLocation
        storeManagerFirstName
        storeManagerLastName
        storeManagerMobile
        storeStatus
        forAdmin {
          emailAddress
        }
      }
      userid {
        id
        firstName
        lastName
        email
        primaryLatitude
        primaryLongitude
        primaryLocation
        phoneNumber
        address {
          id
          type
          houseNumber
          landmark
          locationDescription
          locationLat
          locationLng
        }
      }
      products {
        shopAssistantQuantity
        productPrice
        markedupProductPrice
        productid {
          id
          productname
          images
          image {
            primary
          }
          desc
          price
          uom
          quantity
        }
        quantity
        price
        productDiscountPrice
        status
      }
      totalPrice
      productCount
      totalPriceUser
      deliveryType
      deliveryAddress
      deliveryDate
      deliveryTime
      deliveryLat
      deliveryLng
      mobileNumber
      customerName
      specialInstructions
      deliveryInstructions
      commentsSeller
      stats {
        status
        created
        createdTime
      }
      lastStatus
      shopAssistantId {
        id
        firstName
        lastName
      }
      deliveryPartnerId {
        id
        firstName
        lastName
      }
      deliveryCharge
      finalBill
      finalBillAmount
      totalPriceDelivery
      paymentOrderId
      razorpayOrderid
      razorpayPaymentId
      razorpaySignature
      refund
      paymentStatus
      replacementStatus
      shoppingTotalPrice
      shoppingTotal
      shoppingDiscountPrice
      shoppingTotalPayable
      shoppingTotalPriceGST
      shoppingPackingCharge
      shoppingPlatformFee
      shoppingPackingChargeGstAmount
      shoppingGST
      isReceivedAmountGreater
      deliveryBy
      transferSettlementStatus
      transferSettlement {
        status
        situation
        created
        createdTime
        timestamp
      }
      amountForSeller
      amountForCustomer
      amountForNearshopz
      amountForNearshopzDeliveryPerson
      amountForExternalDeliveryPerson
      extraAmountByNearshopz
      amountAdjustedManually
      editedAmountForSeller
      editedAmountForNearshopz
      editedAmountForNearshopzDeliveryPerson
      editedAmountForExternalDeliveryPerson
      editedExtraAmountByNearshopz
      type
      paymentActive
      couponId
      couponID {
        id
        type
        name
        couponCode
      }
      discountPrice
      travelTime {
        text
        value
      }
      travelDistance {
        text
        value
      }
      estimatedDeliveryTime
      GST
      packingCharge
      handlingCharge
      deliveryTip
      total
      totalPayable
      version
      preparationTime
      packingImages
      razorPayFeesAndTax
      transfers {
        id
        amount
        fees
        tax
        amount_reversed
        transfer_status
        settlement_status
        transferTo
      }
      forceCompleteSettlement
      sellerReimbursement
      editedSellerReimbursement
      totalPriceGST
      totalPriceGSTPercent
      totalPriceMarkupPercent
      deliveryChargeGstAmount
      deliveryChargeGstPercent
      markedupDeliveryChargePercent
      packingChargeGstAmount
      packingChargeGstPercent
      markedupPackingChargePercent
      platformFee
      platformFeeGstAmount
      platformFeeGstPercent
      platformFeePercent
      totalPriceWithoutMarkup
      deliveryChargeWithoutMarkup
      packingChargeWithoutMarkup
      shoppingTotalPriceWithoutMarkup
      shoppingPackingChargeWithoutMarkup
    }
  }
`;
export const CHANGE_ORDER_STATUS_TO_READY = gql`
  mutation ($orderid: ID!, $products: [ProductsInput]) {
    changeOrderStatusToReady(orderid: $orderid, products: $products) {
      id
      orderNumber
      storeid {
        id
        storeName
        deliveryMaxDistance
        storeCoordinates {
          coordinates
        }
        storeLocation
        storeManagerFirstName
        storeManagerLastName
        storeManagerMobile
        storeStatus
        forAdmin {
          emailAddress
        }
      }
      userid {
        id
        firstName
        lastName
        email
        primaryLatitude
        primaryLongitude
        primaryLocation
        phoneNumber
        address {
          id
          type
          houseNumber
          landmark
          locationDescription
          locationLat
          locationLng
        }
      }
      products {
        shopAssistantQuantity
        markedupProductPrice
        productPrice
        productid {
          id
          productname
          images
          image {
            primary
          }
          desc
          price
          uom
          quantity
        }
        quantity
        price
      }
      totalPrice
      productCount
      totalPriceUser
      deliveryType
      deliveryAddress
      deliveryDate
      deliveryTime
      deliveryLat
      deliveryLng
      mobileNumber
      customerName
      specialInstructions
      deliveryInstructions
      commentsSeller
      stats {
        status
        created
        createdTime
        timestamp
      }
      lastStatus
      shopAssistantId {
        id
        firstName
        lastName
      }
      deliveryPartnerId {
        id
        firstName
        lastName
      }
      deliveryCharge
      finalBill
      finalBillAmount
      totalPriceDelivery
      paymentOrderId
      razorpayOrderid
      razorpayPaymentId
      razorpaySignature
      refund
      paymentStatus
      replacementStatus
      shoppingTotalPrice
      shoppingTotal
      shoppingDiscountPrice
      shoppingTotalPayable
      shoppingTotalPriceGST
      shoppingPackingCharge
      shoppingPlatformFee
      shoppingPackingChargeGstAmount
      shoppingGST
      isReceivedAmountGreater
      deliveryBy
      transferSettlementStatus
      transferSettlement {
        status
        situation
        created
        createdTime
        timestamp
      }
      amountForSeller
      amountForCustomer
      amountForNearshopz
      amountForNearshopzDeliveryPerson
      amountForExternalDeliveryPerson
      extraAmountByNearshopz
      amountAdjustedManually
      editedAmountForSeller
      editedAmountForNearshopz
      editedAmountForNearshopzDeliveryPerson
      editedAmountForExternalDeliveryPerson
      editedExtraAmountByNearshopz
      type
      paymentActive
      couponId
      couponID {
        id
        type
        name
        couponCode
      }
      discountPrice
      travelTime {
        text
        value
      }
      travelDistance {
        text
        value
      }
      estimatedDeliveryTime
      GST
      packingCharge
      handlingCharge
      deliveryTip
      total
      totalPayable
      version
      preparationTime
      packingImages
      razorPayFeesAndTax
      transfers {
        id
        amount
        fees
        tax
        amount_reversed
        transfer_status
        settlement_status
        transferTo
      }
      forceCompleteSettlement
      sellerReimbursement
      editedSellerReimbursement
      totalPriceGST
      totalPriceGSTPercent
      totalPriceMarkupPercent
      deliveryChargeGstAmount
      deliveryChargeGstPercent
      markedupDeliveryChargePercent
      packingChargeGstAmount
      packingChargeGstPercent
      markedupPackingChargePercent
      platformFee
      platformFeeGstAmount
      platformFeeGstPercent
      platformFeePercent
      totalPriceWithoutMarkup
      deliveryChargeWithoutMarkup
      packingChargeWithoutMarkup
      shoppingTotalPriceWithoutMarkup
      shoppingPackingChargeWithoutMarkup
    }
  }
`;

export const CHANGE_ORDER_STATUS_TO_DELIVERY = gql`
  mutation ($orderid: ID!, $packingImages: [String]) {
    changeToOutForDelivery(orderid: $orderid, packingImages: $packingImages) {
      id
      orderNumber
      storeid {
        id
        storeName
        deliveryMaxDistance
        storeCoordinates {
          coordinates
        }
        storeLocation
        storeManagerFirstName
        storeManagerLastName
        storeManagerMobile
        storeStatus
        forAdmin {
          emailAddress
        }
      }
      userid {
        id
        firstName
        lastName
        email
        primaryLatitude
        primaryLongitude
        primaryLocation
        phoneNumber
        address {
          id
          type
          houseNumber
          landmark
          locationDescription
          locationLat
          locationLng
        }
      }
      products {
        shopAssistantQuantity
        markedupProductPrice
        productPrice
        productid {
          id
          productname
          images
          image {
            primary
          }
          desc
          price
          uom
          quantity
        }
        quantity
        price
      }
      totalPrice
      productCount
      totalPriceUser
      deliveryType
      deliveryAddress
      deliveryDate
      deliveryTime
      deliveryLat
      deliveryLng
      mobileNumber
      customerName
      specialInstructions
      deliveryInstructions
      commentsSeller
      stats {
        status
        created
        createdTime
        timestamp
      }
      lastStatus
      shopAssistantId {
        id
        firstName
        lastName
      }
      deliveryPartnerId {
        id
        firstName
        lastName
      }
      deliveryCharge
      finalBill
      finalBillAmount
      totalPriceDelivery
      paymentOrderId
      razorpayOrderid
      razorpayPaymentId
      razorpaySignature
      refund
      paymentStatus
      replacementStatus
      shoppingTotalPrice
      shoppingTotal
      shoppingDiscountPrice
      shoppingTotalPayable
      shoppingTotalPriceGST
      shoppingPackingCharge
      shoppingPlatformFee
      shoppingPackingChargeGstAmount
      shoppingGST
      isReceivedAmountGreater
      deliveryBy
      transferSettlementStatus
      transferSettlement {
        status
        situation
        created
        createdTime
        timestamp
      }
      amountForSeller
      amountForCustomer
      amountForNearshopz
      amountForNearshopzDeliveryPerson
      amountForExternalDeliveryPerson
      extraAmountByNearshopz
      amountAdjustedManually
      editedAmountForSeller
      editedAmountForNearshopz
      editedAmountForNearshopzDeliveryPerson
      editedAmountForExternalDeliveryPerson
      editedExtraAmountByNearshopz
      type
      paymentActive
      couponId
      couponID {
        id
        type
        name
        couponCode
      }
      discountPrice
      travelTime {
        text
        value
      }
      travelDistance {
        text
        value
      }
      estimatedDeliveryTime
      GST
      packingCharge
      handlingCharge
      deliveryTip
      total
      totalPayable
      version
      preparationTime
      packingImages
      razorPayFeesAndTax
      transfers {
        id
        amount
        fees
        tax
        amount_reversed
        transfer_status
        settlement_status
        transferTo
      }
      forceCompleteSettlement
      sellerReimbursement
      editedSellerReimbursement
      totalPriceGST
      totalPriceGSTPercent
      totalPriceMarkupPercent
      deliveryChargeGstAmount
      deliveryChargeGstPercent
      markedupDeliveryChargePercent
      packingChargeGstAmount
      packingChargeGstPercent
      markedupPackingChargePercent
      platformFee
      platformFeeGstAmount
      platformFeeGstPercent
      platformFeePercent
      totalPriceWithoutMarkup
      deliveryChargeWithoutMarkup
      packingChargeWithoutMarkup
      shoppingTotalPriceWithoutMarkup
      shoppingPackingChargeWithoutMarkup
    }
  }
`;

export const CHANGE_ORDER_STATUS_TO_COMPLETED = gql`
  mutation ($orderid: ID!, $stats: StatusInput!) {
    updateOrderStatus(orderid: $orderid, stats: $stats) {
      id
      orderNumber
      storeid {
        id
        storeName
        deliveryMaxDistance
        storeCoordinates {
          coordinates
        }
        storeLocation
        storeManagerFirstName
        storeManagerLastName
        storeManagerMobile
        storeStatus
        forAdmin {
          emailAddress
        }
      }
      userid {
        id
        firstName
        lastName
        email
        primaryLatitude
        primaryLongitude
        primaryLocation
        phoneNumber
        address {
          id
          type
          houseNumber
          landmark
          locationDescription
          locationLat
          locationLng
        }
      }
      products {
        shopAssistantQuantity
        markedupProductPrice
        productPrice
        productid {
          id
          productname
          images
          image {
            primary
          }
          desc
          price
          uom
          quantity
        }
        quantity
        price
      }
      totalPrice
      productCount
      totalPriceUser
      deliveryType
      deliveryAddress
      deliveryDate
      deliveryTime
      deliveryLat
      deliveryLng
      mobileNumber
      customerName
      specialInstructions
      deliveryInstructions
      commentsSeller
      stats {
        status
        created
        createdTime
        timestamp
      }
      lastStatus
      shopAssistantId {
        id
        firstName
        lastName
      }
      deliveryPartnerId {
        id
        firstName
        lastName
      }
      deliveryCharge
      finalBill
      finalBillAmount
      totalPriceDelivery
      paymentOrderId
      razorpayOrderid
      razorpayPaymentId
      razorpaySignature
      refund
      paymentStatus
      replacementStatus
      shoppingTotalPrice
      shoppingTotal
      shoppingDiscountPrice
      shoppingTotalPayable
      shoppingTotalPriceGST
      shoppingPackingCharge
      shoppingPlatformFee
      shoppingPackingChargeGstAmount
      shoppingGST
      isReceivedAmountGreater
      deliveryBy
      transferSettlementStatus
      transferSettlement {
        status
        situation
        created
        createdTime
        timestamp
      }
      amountForSeller
      amountForCustomer
      amountForNearshopz
      amountForNearshopzDeliveryPerson
      amountForExternalDeliveryPerson
      extraAmountByNearshopz
      amountAdjustedManually
      editedAmountForSeller
      editedAmountForNearshopz
      editedAmountForNearshopzDeliveryPerson
      editedAmountForExternalDeliveryPerson
      editedExtraAmountByNearshopz
      type
      paymentActive
      couponId
      couponID {
        id
        type
        name
        couponCode
      }
      discountPrice
      travelTime {
        text
        value
      }
      travelDistance {
        text
        value
      }
      estimatedDeliveryTime
      GST
      packingCharge
      handlingCharge
      deliveryTip
      total
      totalPayable
      version
      preparationTime
      packingImages
      razorPayFeesAndTax
      transfers {
        id
        amount
        fees
        tax
        amount_reversed
        transfer_status
        settlement_status
        transferTo
      }
      forceCompleteSettlement
      sellerReimbursement
      editedSellerReimbursement
      totalPriceGST
      totalPriceGSTPercent
      totalPriceMarkupPercent
      deliveryChargeGstAmount
      deliveryChargeGstPercent
      markedupDeliveryChargePercent
      packingChargeGstAmount
      packingChargeGstPercent
      markedupPackingChargePercent
      platformFee
      platformFeeGstAmount
      platformFeeGstPercent
      platformFeePercent
      totalPriceWithoutMarkup
      deliveryChargeWithoutMarkup
      packingChargeWithoutMarkup
      shoppingTotalPriceWithoutMarkup
      shoppingPackingChargeWithoutMarkup
    }
  }
`;
export const ORDER_NEW_UPLOAD = gql`
  mutation (
    $file: Upload
    $size: Int
    $filename: String
    $type: Int
    $orderid: String
  ) {
    imageEditAndUpload(
      file: $file
      size: $size
      filename: $filename
      type: $type
      orderid: $orderid
    ) {
      ETag
      Location
    }
  }
`;

export const GET_ALL_NOTPROCESSED_SETTLEMENTS = gql`
  query ($pagination: PaginationArg, $orderNumber: Float) {
    listAllNotProcessedSettlements(
      pagination: $pagination
      orderNumber: $orderNumber
    ) {
      count
      items {
        orderNumber
        storeid {
          id
        }
        totalPrice
        deliveryDate
        deliveryTime
        mobileNumber
        transfers {
          id
          transfer_status
          settlement_status

          amount_reversed
          fees
          tax
          transferTo
        }
        customerName
        id
        deliveryCharge
        stats {
          status
          created
          createdTime
        }
        transferSettlement {
          status
          created
          createdTime
          timestamp
        }
        transferSettlementStatus
        totalPayable
        deliveryBy
        amountForSeller
        amountForCustomer
        amountForNearshopz
        amountForNearshopzDeliveryPerson
        amountForExternalDeliveryPerson
        extraAmountByNearshopz
        amountAdjustedManually
        editedAmountForSeller
        editedAmountForNearshopz
        razorPayFeesAndTax
        editedAmountForNearshopzDeliveryPerson
        editedRazorPayFeesAndTax
        editedAmountForExternalDeliveryPerson
        paymentStatus
        editedExtraAmountByNearshopz
        forceCompleteSettlement
        razorpayPaymentId
        sellerReimbursement

        editedSellerReimbursement
        totalPriceDelivery
      }
      hasNext
    }
  }
`;

export const GET_ALL_COMPLETED_SETTLEMENTS = gql`
  query ($pagination: PaginationArg) {
    listAllCompletedSettlements(pagination: $pagination) {
      count
      items {
        id

        orderNumber

        storeid {
          id
        }
        transferSettlement {
          status
          created
          createdTime
          timestamp
        }
        totalPrice
        transfers {
          id
          transfer_status
          settlement_status

          amount_reversed
          fees
          tax
          transferTo
        }
        productCount

        totalPriceUser

        deliveryType

        deliveryAddress

        deliveryDate

        deliveryTime

        deliveryLat

        deliveryLng

        mobileNumber

        customerName

        specialInstructions

        deliveryInstructions

        commentsSeller

        lastStatus

        deliveryCharge

        finalBill

        finalBillAmount

        totalPriceDelivery

        paymentOrderId

        razorpayOrderid

        razorpayPaymentId

        razorpaySignature

        refund

        refundId

        refundAmount

        refundStatus

        refundList {
          refundId
          refundAmount
        }

        paymentStatus

        replacementStatus

        shoppingTotalPrice

        shoppingTotal

        shoppingDiscountPrice

        shoppingTotalPayable

        shoppingTotalPriceGST

        shoppingPackingCharge

        shoppingPackingChargeGstAmount

        shoppingGST

        shoppingPlatformFee

        shoppingPlatformFeeGSTAmount

        isReceivedAmountGreater

        deliveryBy

        transferSettlementStatus

        amountForSeller

        amountForCustomer

        amountForNearshopz

        amountForNearshopzDeliveryPerson

        amountForExternalDeliveryPerson

        extraAmountByNearshopz

        amountAdjustedManually

        editedAmountForSeller

        editedAmountForNearshopz

        editedAmountForNearshopzDeliveryPerson

        editedAmountForExternalDeliveryPerson

        editedExtraAmountByNearshopz

        type

        paymentActive

        couponId

        couponID {
          id
        }

        discountPrice

        estimatedDeliveryTime

        GST

        packingCharge

        handlingCharge

        deliveryTip

        total

        totalPayable

        version

        preparationTime

        razorPayFeesAndTax

        editedRazorPayFeesAndTax

        forceCompleteSettlement

        sellerReimbursement

        editedSellerReimbursement

        totalPriceGST

        totalPriceGSTPercent

        deliveryChargeGstAmount

        deliveryChargeGstPercent

        markedupDeliveryChargePercent

        packingChargeGstAmount

        packingChargeGstPercent

        markedupPackingChargePercent

        platformFee

        platformFeeGstAmount

        platformFeeGstPercent

        platformFeePercent
        totalPriceMarkupPercent
        shoppingTotalPriceWithoutMarkup
        deliveryChargeWithoutMarkup
        packingChargeWithoutMarkup
        shoppingTotalPriceWithoutMarkup
        shoppingPackingChargeWithoutMarkup
      }
      hasNext
    }
  }
`;
export const GET_COMPLETEDPAYMENT_SEARCH = gql`
  query (
    $pagination: PaginationArg
    $storeid: ID
    $dateFrom: String
    $dateTo: String
    $orderNumber: Float
  ) {
    searchCompletedSettlementsWithFilters(
      pagination: $pagination
      storeid: $storeid
      dateFrom: $dateFrom
      dateTo: $dateTo
      orderNumber: $orderNumber
    ) {
      count
      items {
        orderNumber
        totalPrice
        deliveryDate
        deliveryTime
        mobileNumber
        customerName
        id
        stats {
          status
          created
          createdTime
        }
        transferSettlement {
          status
          created
          createdTime
        }
        transferSettlementStatus
        totalPayable
        deliveryBy
        amountForSeller
        paymentStatus
        amountForCustomer
        razorpayPaymentId
        amountForNearshopz
        transfers {
          id
          transfer_status
          settlement_status
          amount_reversed
          fees
          tax
          transferTo
        }
        amountForNearshopzDeliveryPerson
        razorPayFeesAndTax
        amountForExternalDeliveryPerson

        extraAmountByNearshopz

        amountAdjustedManually

        editedAmountForSeller
        editedRazorPayFeesAndTax
        editedAmountForNearshopz

        editedAmountForNearshopzDeliveryPerson

        editedAmountForExternalDeliveryPerson

        editedExtraAmountByNearshopz
        forceCompleteSettlement

        sellerReimbursement

        editedSellerReimbursement
        totalPriceDelivery
      }

      hasNext
    }
  }
`;
export const CHECK_SETTELEMENT_STATUS = gql`
  mutation ($orderId: ID, $forceCompleteSettlement: Boolean) {
    checkSettlementStatus(
      orderId: $orderId
      forceCompleteSettlement: $forceCompleteSettlement
    ) {
      orderNumber
      totalPrice
      deliveryDate
      deliveryTime
      mobileNumber
      customerName
      id
      stats {
        status
        created
        createdTime
      }
      transferSettlement {
        status
        created
        createdTime
      }
      transferSettlementStatus
      totalPayable
      deliveryBy
      amountForSeller
      paymentStatus
      amountForCustomer

      amountForNearshopz
      razorpayPaymentId
      amountForNearshopzDeliveryPerson
      razorPayFeesAndTax
      amountForExternalDeliveryPerson

      extraAmountByNearshopz

      amountAdjustedManually

      editedAmountForSeller
      editedRazorPayFeesAndTax
      editedAmountForNearshopz

      editedAmountForNearshopzDeliveryPerson

      editedAmountForExternalDeliveryPerson

      editedExtraAmountByNearshopz
      forceCompleteSettlement

      sellerReimbursement

      editedSellerReimbursement
      totalPriceDelivery
      transfers {
        id
        transfer_status
        settlement_status
        amount_reversed
        fees
        tax
        transferTo
      }
    }
  }
`;
export const UPDATE_SETTELEMENT_DETAILS = gql`
  mutation (
    $orderid: ID
    $editedAmountForSeller: Float
    $editedAmountForNearshopz: Float
    $editedAmountForNearshopzDeliveryPerson: Float
    $editedAmountForExternalDeliveryPerson: Float
    $editedExtraAmountByNearshopz: Float
    $settlementComment: String
    $editedSellerReimbursement: Float
  ) {
    updateSettlementDetails(
      orderid: $orderid
      editedAmountForSeller: $editedAmountForSeller
      editedAmountForNearshopz: $editedAmountForNearshopz
      editedAmountForNearshopzDeliveryPerson: $editedAmountForNearshopzDeliveryPerson
      editedAmountForExternalDeliveryPerson: $editedAmountForExternalDeliveryPerson
      editedExtraAmountByNearshopz: $editedExtraAmountByNearshopz
      settlementComment: $settlementComment
      editedSellerReimbursement: $editedSellerReimbursement
    ) {
      orderNumber
      totalPrice
      deliveryDate
      deliveryTime
      mobileNumber
      customerName
      id
      stats {
        status
        created
        createdTime
      }
      transferSettlement {
        status
        created
        createdTime
      }
      transferSettlementStatus
      totalPayable

      amountForSeller
      paymentStatus
      amountForCustomer
      amountForNearshopz
      amountForNearshopzDeliveryPerson
      razorPayFeesAndTax
      amountForExternalDeliveryPerson
      extraAmountByNearshopz
      amountAdjustedManually
      editedAmountForSeller
      editedRazorPayFeesAndTax
      editedAmountForNearshopz
      editedAmountForNearshopzDeliveryPerson
      editedAmountForExternalDeliveryPerson
      editedExtraAmountByNearshopz
      forceCompleteSettlement
      sellerReimbursement
      editedSellerReimbursement
      totalPriceDelivery
      razorpayPaymentId
    }
  }
`;

export const CREATE_TRANSFER = gql`
  mutation ($orderId: ID!, $transferTo: String!, $razorPayAccountId: String) {
    createTransfer(
      orderId: $orderId
      transferTo: $transferTo
      razorPayAccountId: $razorPayAccountId
    ) {
      orderNumber
      totalPrice
      deliveryDate
      deliveryTime
      mobileNumber
      customerName
      id
      stats {
        status
        created
        createdTime
      }
      transferSettlement {
        status
        created
        createdTime
      }
      transfers {
        id
        transfer_status
        settlement_status
        amount_reversed
        fees
        tax
        transferTo
      }
      transferSettlementStatus
      totalPayable
      deliveryBy
      amountForSeller
      paymentStatus
      amountForCustomer
      amountForNearshopz
      amountForNearshopzDeliveryPerson
      razorPayFeesAndTax
      amountForExternalDeliveryPerson
      extraAmountByNearshopz
      amountAdjustedManually
      editedAmountForSeller
      editedRazorPayFeesAndTax
      editedAmountForNearshopz
      editedAmountForNearshopzDeliveryPerson
      editedAmountForExternalDeliveryPerson
      editedExtraAmountByNearshopz
      forceCompleteSettlement
      sellerReimbursement
      editedSellerReimbursement
      totalPriceDelivery
      razorpayPaymentId
    }
  }
`;

export const GET_SETTINGS = gql`
  query {
    getSettings {
      id
      defaultRestaurantProductMarkupPercent
      defaultPackingMarkupPercent
      defaultDeliveryChargeMarkupPercent
      defaultPlatformFeePercent
      restaurantProductPriceGSTPercent
      restaurantPackingChargeGSTPercent
      supermarketPackingChargeGSTPercent
      deliveryChargeGSTPercent
      platformFeeGSTPercent
    }
  }
`;

export const UPDATE_SETTINGS = gql`
  mutation (
    $id: ID
    $defaultRestaurantProductMarkupPercent: Float
    $defaultPackingMarkupPercent: Float
    $defaultDeliveryChargeMarkupPercent: Float
    $defaultPlatformFeePercent: Float
    $restaurantProductPriceGSTPercent: Float
    $restaurantPackingChargeGSTPercent: Float
    $supermarketPackingChargeGSTPercent: Float
    $deliveryChargeGSTPercent: Float
    $platformFeeGSTPercent: Float
  ) {
    updateAdminSettings(
      id: $id
      defaultRestaurantProductMarkupPercent: $defaultRestaurantProductMarkupPercent
      defaultPackingMarkupPercent: $defaultPackingMarkupPercent
      defaultDeliveryChargeMarkupPercent: $defaultDeliveryChargeMarkupPercent
      defaultPlatformFeePercent: $defaultPlatformFeePercent
      restaurantProductPriceGSTPercent: $restaurantProductPriceGSTPercent
      restaurantPackingChargeGSTPercent: $restaurantPackingChargeGSTPercent
      supermarketPackingChargeGSTPercent: $supermarketPackingChargeGSTPercent
      deliveryChargeGSTPercent: $deliveryChargeGSTPercent
      platformFeeGSTPercent: $platformFeeGSTPercent
    ) {
      id
      defaultRestaurantProductMarkupPercent
      defaultPackingMarkupPercent
      defaultDeliveryChargeMarkupPercent
      defaultPlatformFeePercent
      restaurantProductPriceGSTPercent
      restaurantPackingChargeGSTPercent
      supermarketPackingChargeGSTPercent
      deliveryChargeGSTPercent
      platformFeeGSTPercent
    }
  }
`;
export const UPDATE_DELIVERYBY = gql`
  mutation ($orderid: ID, $deliveryBy: Int) {
    updateDeliveryBy(orderid: $orderid, deliveryBy: $deliveryBy) {
      id
      orderNumber
      deliveryBy
      paymentStatus
    }
  }
`;

export const GET_LINKED_ACCOUNTS = gql`
  query ($linkedAccountUser: Int!) {
    getExternalDeliveryPersonLinkedAccounts(
      linkedAccountUser: $linkedAccountUser
    ) {
      id
      email
      phone
      legal_business_name
      customer_facing_business_name
      business_type
      razorPayAccountId
      type
      profile {
        category
        subcategory
        addresses {
          registered {
            street1
            street2
            city
            state
            postal_code
            country
          }
        }
      }
      linkedAccountUser
      bankDetails {
        bankAccountNumber
        accountHolderName
        IFSCcode
      }
    }
  }
`;

export const ADD_LINKEDACCOUNT = gql`
  mutation (
    $email: String
    $phone: String
    $legal_business_name: String
    $customer_facing_business_name: String
    $business_type: String
    $razorPayAccountId: String
    $profile: linkedAccountProfileInput
    $linkedAccountUser: Int
  ) {
    createRazorpayLinkedAccount(
      email: $email
      phone: $phone
      legal_business_name: $legal_business_name
      customer_facing_business_name: $customer_facing_business_name
      business_type: $business_type
      razorPayAccountId: $razorPayAccountId
      profile: $profile
      linkedAccountUser: $linkedAccountUser
    ) {
      id
      email
      phone
      legal_business_name
      customer_facing_business_name
    }
  }
`;
