import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import EditImageField from "../EditImageLogo";
import TextField from "@material-ui/core/TextField";
import EditLocation from "../EditLocation";
import ImageEditWindow from "../ImageEditModal";
import Grid from "@material-ui/core/Grid";
import { useFormik } from "formik";
import * as Yup from "yup";
import "./index.css";
import EditDisplayImageField from "../EditDisplayImage";
import FormLabel from "@material-ui/core/FormLabel";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import CardMedia from "@material-ui/core/CardMedia";
import MenuItem from "@mui/material/MenuItem";
const useStyles = makeStyles({
  root: {
    maxWidth: 345,
  },
  media: {
    height: 140,
  },
});

const Business = [
  { id: 1, name: "Supermarkets" },
  { id: 2, name: "Restaurants" },
  { id: 3, name: "Bakery" },
  { id: 4, name: "Fish & Meat" },
  { id: 5, name: "Home Bakery" },
  { id: 6, name: "Fruits & Vegetables" },
  { id: 7, name: "Pet Care" },
  { id: 8, name: "Home Business" },
  { id: 9, name: "Electronics" },
  { id: 10, name: "Lifestyle & Fashion" },
  { id: 11, name: "Sports & Fitness" },
  { id: 12, name: "Home Decor" },
  { id: 13, name: "Medicines" },
];

const StoreDetailsNew = (props) => {
  React.useEffect(() => {
    localStorage.removeItem("displayimage");
    document.body.scrollTop = 0;
    window.scrollTo(0, 0);
  }, []);

  const findBusinessName = (id) => {
    const business = Business.find((b) => b.id === id);
    return business ? business.name : "";
  };

  const businessTypeIdArray = props?.searchDetails?.store?.businessTypeId;
  const businessTypeId = Array.isArray(businessTypeIdArray)
    ? businessTypeIdArray[0]
    : businessTypeIdArray;
  const initialBusinessName = findBusinessName(businessTypeId);

  const [state, setState] = React.useState({
    imageEditModal: false,
    initialDetailState: props.retailerDetails && props.retailerDetails,
  });

  const [isEditing, setIsEditing] = React.useState(false);

  const handleImageClick = () => {
    setIsEditing(true);
  };

  const handleImageEdit = (newImage) => {
    // Your logic to handle image edit can go here
    setIsEditing(false); // Optionally toggle back after editing
  };

  const classes = useStyles();
  let storeDisplayImageUrl =
    props?.searchDetails?.store?.storeDisplayImageUrl || "";

  var tempLink = document.createElement("a");
  document.body.appendChild(tempLink);
  const download = async (e) => {
    // var fileIndex = fileUrls.length*1-1*1;
    var fileUrl = e;
    tempLink.setAttribute("href", fileUrl);
    tempLink.setAttribute(
      "download",
      fileUrl.split("/")[fileUrl.split("/").length * 1 - 1 * 1]
    );
    tempLink.click();
  };

  const storeLabels = [
    "Store Name",
    "Store Description",
    "Store Manager FirstName",
    "Store Manager LastName",
    "Store Manager Mobile",
    "Store Manager Alternate Contact Number",
    "Fssai Number",
  ];

  const validationSchema = Yup.object().shape({
    storeName: Yup.string().required("Please enter Store Name"),
    storeDescription: Yup.string().required("Please enter Store Description"),
    // storeLocation: Yup.string().required("Please enter Store Location"),
    storeManagerFirstName: Yup.string().required(
      "Please enter Store Manager First Name"
    ),
    storeManagerLastName: Yup.string().required(
      "Please enter Store Manager Last Name"
    ),
    storeManagerMobile: Yup.string()
      .trim("Space not allowed")
      .matches(/^[0-9]*$/, "Please enter only numbers")
      .min(10, "Please enter 10 digit Mobile number")
      .max(10, "Please enter 10 digit Mobile number")
      .required("Please enter Mobile number"),
    storeManagerPhone: Yup.string()
      .trim("Space not allowed")
      .matches(/^[0-9]*$/, "Please enter only numbers")
      .min(10, "Please enter 10 digit Mobile number")
      .max(10, "Please enter 10 digit Mobile number"),
    fssaiNo: Yup.string()
      .matches(/^[0-9]{14}$/, "Please enter a valid 14-digit FSSAI number")
      .required("Please enter FSSAI number"),
  });

  const formik = useFormik({
    initialValues: {
      storeName: props.searchDetails.store.storeName || "",
      storeDescription: props.searchDetails.store.storeDescription || "",
      storeManagerFirstName:
        props.searchDetails.store.storeManagerFirstName || "",
      storeManagerLastName:
        props.searchDetails.store.storeManagerLastName || "",
      storeManagerPhone: props.searchDetails.store.storeManagerPhone || "",
      storeManagerMobile: props.searchDetails.store.storeManagerMobile || "",
      fssaiNo: props.searchDetails.store.fssaiNo || "",
      storeLocation: props.searchDetails.store.storeLocation,
      storeCoordinates: props?.searchDetails?.store?.storeCoordinates,
      businessTypeId: businessTypeId || "",
      businessType: initialBusinessName,
      // storeDisplayImageUrl:props?.searchDetails?.store?.storeDisplayImageUrl || ""
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      console.log("check", values);
      if (values.storeManagerPhone === values.storeManagerMobile) {
        formik.setFieldError(
          "storeManagerMobile",
          "Alternate number and mobile number should not be the same"
        );
      } else {
        props.saveDetails({
          ...props.searchDetails.store,
          storeName: values.storeName,
          storeDescription: values.storeDescription,
          storeManagerFirstName: values.storeManagerFirstName,
          storeManagerLastName: values.storeManagerLastName,
          storeManagerPhone: values.storeManagerPhone,
          storeManagerMobile: values.storeManagerMobile,
          fssaiNo: values.fssaiNo,
          storeLocation:
            props?.edittedStoreLocation?.params == null
              ? props.searchDetails.store.storeLocation
              : props?.edittedStoreLocation?.params,
          storeCoordinates: props?.searchDetails?.store?.storeCoordinates,
          businessTypeId: values.businessTypeId,
          businessType: values.businessTypeName,
          storeDisplayImageUrl: localStorage.getItem("displayimage"),
        });
        props.next();
      }
    },
  });

  console.log("initial", formik.initialValues);

  let storeTextField = [
    "storeName",
    "storeDescription",
    "storeManagerFirstName",
    "storeManagerLastName",
    "storeManagerMobile",
    "storeManagerPhone",
    "fssaiNo",
  ];

  // const handleImageEdit = () => {
  //   setState({ imageEditModal: !state.imageEditModal });
  //   props.newSignedUrl &&
  //     props.postNewUrl({
  //       url: props.newSignedUrl,
  //       details: props.searchDetails.store,
  //     });
  // };

  return (
    <Container>
      <div style={{ fontWeight: 600 }}>
        <h4
          style={{
            paddingTop: "30px",
            fontWeight: 500,
            textAlign: "center",
            color: "black",
            textDecoration: "underline",
          }}
        >
          {"Store Details"}
        </h4>
        {state.imageEditModal ? (
          <ImageEditWindow {...props} handleImageEdit={handleImageEdit} />
        ) : null}
        <form onSubmit={formik.handleSubmit}>
          <ul>
            <Row>
              {storeTextField.map((storeDetail, index) => {
                return index < 7 ? (
                  <Col
                    md={6}
                    key={index}
                    style={{ paddingTop: 30, borderRadius: "15px" }}
                  >
                    <TextField
                      InputProps={{ style: { height: 50 } }}
                      label={storeLabels[index]}
                      name={storeDetail}
                      value={formik.values[storeDetail]}
                      onChange={formik.handleChange}
                      error={
                        formik.touched[storeDetail] &&
                        Boolean(formik.errors[storeDetail])
                      }
                      helperText={
                        formik.touched[storeDetail] &&
                        formik.errors[storeDetail]
                      }
                      variant="outlined"
                      fullWidth
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </Col>
                ) : null;
              })}
              <Col md={6} style={{ paddingTop: 30, borderRadius: "15px" }}>
                <TextField
                  InputProps={{ style: { height: 50 } }}
                  label="Business Type"
                  name="businessTypeId"
                  select
                  value={formik.values.businessTypeId}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.businessTypeId &&
                    Boolean(formik.errors.businessTypeId)
                  }
                  helperText={
                    formik.touched.businessTypeId &&
                    formik.errors.businessTypeId
                  }
                  variant="outlined"
                  fullWidth
                  InputLabelProps={{
                    shrink: true,
                  }}
                >
                  {Business.map((business) => (
                    <MenuItem key={business.id} value={business.id}>
                      {business.name}
                    </MenuItem>
                  ))}
                </TextField>
              </Col>
            </Row>
            <EditLocation {...props} />
            <Grid item xs={12} style={{ paddingTop: 50 }}>
              <EditImageField {...props} handleImageEdit={handleImageEdit} />
            </Grid>
            <Grid item xs={12} style={{ paddingTop: 50 }}>
              <FormLabel component="legend" style={{ textAlign: "left" }}>
                Store Display Image{" "}
              </FormLabel>
              {!isEditing && storeDisplayImageUrl ? (
                <Card className={classes.root}>
                  <CardActionArea onClick={handleImageClick}>
                    <CardMedia
                      className={classes.media}
                      image={storeDisplayImageUrl}
                      title="Store Logo"
                    />
                    <CardActions>
                      <Button
                        size="small"
                        onClick={() => download(storeDisplayImageUrl)}
                      >
                        Download
                      </Button>
                    </CardActions>
                  </CardActionArea>
                </Card>
              ) : (
                <EditDisplayImageField
                  {...props}
                  handleImageEdit={handleImageEdit}
                />
              )}
            </Grid>
          </ul>
          <div className="buttons">
            <button type="button" onClick={props.back} className="nextbutton">
              Back
            </button>
            <button type="submit" className="nextbutton" id="nex">
              {"Save & Next"}
            </button>
          </div>
        </form>
      </div>
    </Container>
  );
};
export default StoreDetailsNew;
