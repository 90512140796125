import React from "react";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import FormControl from "@material-ui/core/FormControl";
import SearchOrderResults from "../SearchOrderResults";
// import clearSearchOrder from "../SearchOrderResults";
import { Toolbar } from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import { Formik } from "formik";
import * as Yup from "yup";
import "./index.css";

const schema = Yup.object().shape({
  customerMobile: Yup.string()
    .matches(/^\S*$/, "Space is not allowed")
    //eslint-disable-next-line
    .matches(/^[0-9]*$/, "Please enter only numbers")
    .min(10, "Please enter 10 digit Mobile number")
    .max(10, "Please enter 10 digit Mobile number"),
  orderNumber: Yup.string()
    .trim("Space Not allowed")
    .matches(/^[0-9]*$/, "Please enter only numbers"),
});

class OrderSearch extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      orderstatus: "",
      deliveryDateFrom: "",
      deliveryDateTo: "",
      dateFrom: "",
      dateTo: "",
      orderNumber: "",
      customerMobile: "",
      first: 10,
      offset: 0,
      order: [],
    };
  }
  // componentDidUpdate(prevProps) {
  //   // Typical usage (don't forget to compare props):
  //   if (this.props.searchOrderData !== prevProps.searchOrderData) {
  //     this.setState({
  //       order: [this.props.searchOrderData
  //         && this.props.searchOrderData.items
  //         && this.props.searchOrderData.items.map((p) => (p))]
  //     }
  //       , () => {
  //         this.props.setOrder(this.state.order)
  //       }
  //     )

  //   }
  // }

  componentDidMount(){
    this.props.clearSearchOrderResult()
  }

  render() {
    const statusFilters = [
      { option: "Order-Placed", value: "Order-Placed" },
      { option: "Shopping-In-Progress", value: "Shopping-In-Progress" },
      { option: "Order-Ready", value: "Order-Ready" },
      { option: "Order-Rejected", value: "Order-Rejected" },
      { option: "Order-Cancelled", value: "Order-Cancelled" },
      { option: "Out-For-Delivery", value: "Out-For-Delivery" },
      { option: "Order-Completed", value: "Completed" },
    ];
    const loadingModal = (
      <div style={{ justifyContent: "center" }}>
        <CircularProgress style={{ spanor: "grey" }} />
      </div>
    );
    const handleSearchField = (e) => {
      let { value } = e.target;
      this.setState({
        ...this.state,
        [e.target.name]: value,
      });
    };
    const loadPrevious = () => {
      this.setState({ offset: this.state.offset - 10 }, () => {
        this.props.getSearchOrderResult({ ...this.state });
      });
    };
    const loadMore = () => {
      this.setState({ offset: this.state.offset + 10 }, () => {
        this.props.getSearchOrderResult({ ...this.state });
      });
    };
    const onClear = (resetForm) => {
      this.setState({
        orderstatus: "",
        deliveryDateFrom: "",
        deliveryDateTo: "",
        dateFrom: "",
        dateTo: "",
        orderNumber: "",
        customerMobile: "",
        first: 10,
        offset: 0,
        order: [],
      });
      this.props.clearSearchOrder();
      resetForm()
    };

    const searchOrderHandle = () => {
      let {
        orderstatus,
        deliveryDateFrom,
        deliveryDateTo,
        dateFrom,
        dateTo,
        orderNumber,
        customerMobile,
        first,
        offset,
      } = this.state;
      this.props.clearSearchOrder();
      if (
        (this.state.deliveryDateTo !== "" &&
          this.state.deliveryDateFrom === "") ||
        (this.state.deliveryDateTo === "" && this.state.deliveryDateFrom !== "")
      ) {
        alert("Please select both Delivery date from and to.");
      } else if (
        (this.state.dateTo !== "" && this.state.dateFrom === "") ||
        (this.state.dateTo === "" && this.state.dateFrom !== "")
      ) {
        alert("Please select both Order From and To dates.");
      } else if (
        new Date(this.state.dateFrom) > new Date(this.state.dateTo) ||
        new Date(this.state.deliveryDateFrom) >
          new Date(this.state.deliveryDateTo)
      ) {
        alert("From date must be less than To date");
      } else if (this.state.status !== null) {
        this.props.getSearchOrderResult({
          orderstatus,
          deliveryDateFrom,
          deliveryDateTo,
          dateFrom,
          dateTo,
          orderNumber,
          customerMobile,
          first,
          offset,
        });
      } else {
        this.props.getSearchOrderResult({ ...this.state });
      }
    };
    return (
      <>
        <div
          style={{
            backgroundColor: "white",
            transform: "translateY(100px)",
            width: "100%",
            borderRadius: "30px",
            padding: "15px",
            boxShadow:
              "0px 11px 15px -7px rgb(0 0 0 / 20%), 0px 24px 38px 3px rgb(0 0 0 / 14%), 0px 9px 46px 8px rgb(0 0 0 / 12%)",
          }}
          position="sticky"
        >
          <Toolbar
            style={{
              textAlign: "center",
              justifyContent: "center",
            }}
          >
            <h3
              style={{
                fontWeight: 600,
                textAlign: "center",
                spanor: "black",
                textDecoration: "underline",
              }}
            >
              {"Search Order"}
            </h3>
          </Toolbar>
          <Formik
            initialValues={{
              customerMobile: "",
              orderNumber:"",
            }}
            validationSchema={schema}
            // tell the formik to validate onBlur
            validateOnBlur
            onSubmit={(values) => {
              this.setState({
                ...this.state,
                customerMobile: values.customerMobile,
                orderNumber: values.orderNumber,
              });
              let {
                orderstatus,
                deliveryDateFrom,
                deliveryDateTo,
                dateFrom,
                dateTo,
                orderNumber,
                customerMobile,
                first,
                offset,
              } = this.state;
              this.props.clearSearchOrder();
              if (
                (this.state.deliveryDateTo !== "" &&
                  this.state.deliveryDateFrom === "") ||
                (this.state.deliveryDateTo === "" &&
                  this.state.deliveryDateFrom !== "")
              ) {
                alert("Please select both Delivery date from and to.");
              } else if (
                (this.state.dateTo !== "" && this.state.dateFrom === "") ||
                (this.state.dateTo === "" && this.state.dateFrom !== "")
              ) {
                alert("Please select both Order From and To dates.");
              } else if (
                new Date(this.state.dateFrom) > new Date(this.state.dateTo) ||
                new Date(this.state.deliveryDateFrom) >
                  new Date(this.state.deliveryDateTo)
              ) {
                alert("From date must be less than To date");
              } else if (this.state.status !== null) {
                this.props.getSearchOrderResult({
                  orderstatus,
                  deliveryDateFrom,
                  deliveryDateTo,
                  dateFrom,
                  dateTo,
                  orderNumber,
                  customerMobile,
                  first,
                  offset,
                });
              } else {
                this.props.getSearchOrderResult({ ...this.state });
              }
              // here you have the access to the form data
              // values.first_name, values_last_name, values_email, values_password
              //if register function succesful, redirect to login page
              // register(values).then((res) => {
              //   this.props.history.push(`/login`);
              // })
            }}
          >
            {(props) => {
              const {
                touched,
                errors,
                handleSubmit,
                values,
                handleChange,
                handleBlur,
                resetForm
              } = props;
              return (
                <form noValidate onSubmit={handleSubmit} style={{position:'relative',right:'12px'}}>
                  <ul>
                    <Grid container spacing={3} style={{ padding: "20px" }}>
                      <Grid item md={6} xs={12}>
                        <TextField
                          id="filled-read-only-input"
                          label="Order Number"
                          type="text"
                          name="orderNumber"
                          value={values.orderNumber}
                          variant="outlined"
                          InputLabelProps={{
                            shrink: true,
                          }}
                          onChange={handleChange}
                          error={
                            touched.orderNumber && Boolean(errors.orderNumber)
                          }
                          helperText={touched.orderNumber && errors.orderNumber}
                          fullWidth
                        />
                      </Grid>
                      <Grid item md={6} xs={12}>
                        <TextField
                          id="filled-read-only-input"
                          label="Customer Mobile Number"
                          type="text"
                          name="customerMobile"
                          value={values.customerMobile}
                          variant="outlined"
                          InputLabelProps={{
                            shrink: true,
                          }}
                          onChange={handleChange}
                          error={
                            touched.customerMobile &&
                            Boolean(errors.customerMobile)
                          }
                          helperText={
                            touched.customerMobile && errors.customerMobile
                          }
                          fullWidth
                        />
                      </Grid>
                      <Grid item md={6} xs={12}>
                        <TextField
                          type="date"
                          label="Delivery From"
                          name="deliveryDateFrom"
                          defaultValue={this.state.deliveryDateFrom}
                          value={this.state.deliveryDateFrom}
                          variant="outlined"
                          InputLabelProps={{
                            shrink: true,
                          }}
                          onChange={(e) => {
                            handleSearchField(e);
                          }}
                          fullWidth
                        />
                      </Grid>
                      <Grid item md={6} xs={12}>
                        <TextField
                          type="date"
                          label="Delivery To"
                          name="deliveryDateTo"
                          defaultValue={this.state.deliveryDateTo}
                          value={this.state.deliveryDateTo}
                          variant="outlined"
                          InputLabelProps={{
                            shrink: true,
                          }}
                          onChange={(e) => {
                            handleSearchField(e);
                          }}
                          fullWidth
                        />
                      </Grid>
                      <Grid item md={6} xs={12}>
                        <TextField
                          type="date"
                          label="Orders From"
                          name="dateFrom"
                          defaultValue={this.state.dateFrom}
                          value={this.state.dateFrom}
                          variant="outlined"
                          InputLabelProps={{
                            shrink: true,
                          }}
                          onChange={(e) => {
                            handleSearchField(e);
                          }}
                          fullWidth
                        />
                      </Grid>
                      <Grid item md={6} xs={12}>
                        <TextField
                          type="date"
                          label="Orders To"
                          name="dateTo"
                          defaultValue={this.state.dateTo}
                          value={this.state.dateTo}
                          variant="outlined"
                          InputLabelProps={{
                            shrink: true,
                          }}
                          onChange={(e) => {
                            handleSearchField(e);
                          }}
                          fullWidth
                        />
                      </Grid>
                      <Grid item md={6} xs={12}>
                        <FormControl
                          style={{ width: "100%" }}
                          variant="outlined"
                        >
                          <InputLabel id="demo-simple-select-label">
                            Order Status
                          </InputLabel>
                          <Select
                            onChange={handleSearchField}
                            value={this.state.orderstatus}
                            name="orderstatus"
                            label="Order Status"
                          >
                            {statusFilters.map((statusFilter, index) => {
                              return (
                                <MenuItem
                                  key={index}
                                  style={{ width: "100%" }}
                                  value={statusFilter.value}
                                >
                                  {statusFilter.option}
                                </MenuItem>
                              );
                            })}
                          </Select>
                        </FormControl>
                      </Grid>
                    </Grid>
                    <Grid container spacing={3} style={{ padding: "20px",position:'relative',right:'2px' }}>
                      <Grid item md={6} xs={12}>
                        <button
                          className="clearBtn"
                          type="button"
                          onClick={() => onClear(resetForm)}
                        >
                          Clear Search
                        </button>
                      </Grid>
                      <Grid item md={6} xs={12}>
                        <button
                          className="searchBtn"
                          type="submit"
                        >
                          Search Order
                        </button>
                      </Grid>
                    </Grid>
                  </ul>
                </form>
              );
            }}
          </Formik>
          {this.props.searchingorder && loadingModal}
          {this.props.searchOrderSuccess ? (
            <SearchOrderResults
              order={this.props.Orders}
              orderstatus={this.state.orderstatus}
              deliveryDateFrom={this.state.deliveryDateFrom}
              deliveryDateTo={this.state.deliveryDateTo}
              dateFrom={this.state.dateFrom}
              dateTo={this.state.dateTo}
              orderNumber={this.state.orderNumber}
              customerMobile={this.state.customerMobile}
              {...this.props}
            />
          ) : null}
          <div style={{ dispaly: "flex", width: "100%" }}>
            <span
              style={{
                width: "50%",
                float: "left",
                justifyContent: "left",
                alignItems: "left",
                textAlign: "left",
              }}
            >
              {this.state.offset > 0 ? (
                <button
                  style={{
                    padding: "10px",
                    backgroundImage:
                      "linear-gradient( 59deg, rgb(83,170,73) 0%, rgb(117,194,36) 100%)",
                    boxShadow: "0px 14px 24px 0px rgba(93, 183, 96, 0.38)",
                    height: "100%",
                    fontSize: "18px",
                    fontWeight: 600,
                    borderRadius: "5px",
                    border: "none",
                    overflow: "hidden",
                    outline: "none",
                    fontFamily: " Varela Round, sans-serif",
                    textTransform: "none",
                    textAign: "center",
                    width: "40%",
                    cursor: "pointer",
                    color: "#ffffff",
                  }}
                  onClick={loadPrevious}
                >
                  Previous
                </button>
              ) : null}
            </span>
            <span
              style={{
                width: "50%",
                float: "right",
                justifyContent: "right",
                alignItems: "right",
                textAlign: "right",
              }}
            >
              {this.props.searchOrderData &&
                this.props.searchOrderData.hasNext &&
                this.props.searchOrderData.hasNext === true && (
                  <button
                    style={{
                      padding: "10px",
                      backgroundImage:
                        "linear-gradient( 59deg, rgb(83,170,73) 0%, rgb(117,194,36) 100%)",
                      boxShadow: "0px 14px 24px 0px rgba(93, 183, 96, 0.38)",
                      height: "100%",
                      fontSize: "18px",
                      fontWeight: 600,
                      borderRadius: "5px",
                      border: "none",
                      overflow: "hidden",
                      outline: "none",
                      fontFamily: " Varela Round, sans-serif",
                      textTransform: "none",
                      textAign: "center",
                      width: "40%",
                      cursor: "pointer",
                      color: "#ffffff",
                    }}
                    onClick={loadMore}
                  >
                    Next
                  </button>
                )}
            </span>
          </div>
        </div>
      </>
    );
  }
}
export default OrderSearch;
