//import React from 'react'
import {
  Grid,
  Box,
  Dialog,
  DialogTitle,
  InputAdornment,
  //makeStyles,
  Paper,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import React, { useState, useEffect } from "react";
import "./index.css";
import CustomTextField from "../CommonComponents/input";
import searchcopy from "../utils/Icon/searchcopy.svg";
import CustomCard from "../CommonComponents/card";
import Arrow from "../utils/Icon/Arrow.svg";
import { SpaRounded } from "@material-ui/icons";
import CustomButton from "../CommonComponents/button";
import CustomBadge from "../CommonComponents/badge";
import { DialogContent } from "@material-ui/core";
import {
  Timeline,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineItem,
  TimelineSeparator,
} from "@material-ui/lab";
import View from "./View";
import {
  contentFontsize_14,
  contentFontSize_16,
  contentFontSize_20,
} from "../Typography";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const useStyles = makeStyles({
  timeline: {
    transform: "rotate(90deg)",
  },
  timelineContentContainer: {
    textAlign: "left",
  },
  timelineContent: {
    display: "inline-block",
    transform: "rotate(-90deg)",
    textAlign: "center",
    minWidth: 50,
  },
  timelineIcon: {
    transform: "rotate(-90deg)",
  },
});
function PaymentOne(props) {
  const [first, setFirst] = useState(10);

  const history = useHistory();

  const handleViewClick = (order) => {
    localStorage.setItem("PendingOrderId", order.id);
    history.push("/home/Payment/NotProcessed/ViewTransaction");
  };
  const [orderList, setOrderList] = useState([]);
  const [state, setState] = useState("Settlements In-Progress");
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("sm"));
  const matchessm = useMediaQuery(theme.breakpoints.down("md"));
  const matcheslg = useMediaQuery(theme.breakpoints.down("lg"));

  const upXlmatch = useMediaQuery(theme.breakpoints.up("xl"));
  const xlmatch = useMediaQuery(theme.breakpoints.down("xl"));
  const lgmatch = useMediaQuery(theme.breakpoints.down("lg"));
  const mdmatch = useMediaQuery(theme.breakpoints.down("md"));
  const smmatch = useMediaQuery(theme.breakpoints.down("sm"));
  const xsmatch = useMediaQuery(theme.breakpoints.down("xs"));
  const [one, setOne] = useState(true);
  const [two, setTwo] = useState(false);
  const [three, setThree] = useState(false);
  const [offset, setOffset] = useState(0);
  const [open, setOpen] = useState(false);
  function handleView() {
    setView(true);
  }
  const [view, setView] = useState(false);

  useEffect(() => {
    props.getAllNotProcessedSettlements({ offset, first });
  }, [offset]);

  useEffect(() => {
    const pendingOrders =
      props?.NotProcessedPaymentData?.data?.listAllNotProcessedSettlements
        ?.items || [];

    setOrderList((prevOrderList) => {
      const existingIds = new Set(prevOrderList.map((order) => order.id));
      const uniqueOrders = pendingOrders.filter(
        (order) => !existingIds.has(order.id)
      );
      return [...prevOrderList, ...uniqueOrders];
    });
  }, [props?.NotProcessedPaymentData]);

  const handleLoadMore = () => {
    setOffset((prevOffset) => prevOffset + 10);
  };
  return (
    <div style={{ paddingTop: "20px", boxSizing: "border-box" }}>
      {orderList?.map((order, index) => (
        <Grid sx={{ paddingLeft: "0px", paddingTop: "20px" }}>
          <CustomCard
            padding={"30px"}
            width="100%"
            height="100%"
            borderLeft={`8px solid ${
              order?.transferSettlement[0]?.status === "Pending"
                ? "#EA4335"
                : "#FF9900"
            }`}
            sx={{ display: "flex", alignItems: "center" }}
          >
            <Box>
              <Grid
                container
                spacing={2}
                sx={{ paddingLeft: "0px", paddingTop: "0px" }}
              >
                <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "10px",
                      alignItems: "flex-start",
                      justifyContent: "flex-start",
                    }}
                  >
                    <Typography
                      className="Card-text"
                      fontSize={contentFontSize_20}
                      color={"#2D93FB"}
                      style={{ cursor: "pointer", textDecoration: "underline" }}
                      sx={{ fontFamily: "Roboto", fontWeight: "600" }}
                      // onClick={() => handleOrder(order.id)}
                    >
                      #{order?.orderNumber}
                    </Typography>
                    <Typography
                      fontSize={contentFontsize_14}
                      // className="Card-subtext"
                    >
                      {order?.transferSettlement[0]?.created}{" "}
                      {order?.transferSettlement[0]?.createdTime}
                    </Typography>
                  </div>
                </Grid>

                <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                  <div
                    style={{
                      display: "flex",
                      gap: "10px",
                      marginTop: "15px",
                    }}
                  >
                    <Typography
                      className="Card-price"
                      fontSize={contentFontSize_16}
                      color={"black"}
                      sx={{ fontFamily: "Roboto", fontWeight: "600" }}
                    >
                      ₹{" "}
                      {order?.totalPayable
                        ? order.totalPayable.toFixed(2)
                        : "0.00"}
                    </Typography>
                  </div>
                </Grid>

                {/* Adjusted status Grid item */}
                <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                  <div
                    style={{
                      display: "flex",
                      gap: "10px",
                      marginTop: "15px",
                      whiteSpace: "nowrap", // Ensure the text doesn't wrap
                      overflow: "hidden", // Prevent overflow
                      textOverflow: "ellipsis", // Add ellipsis if the text overflows
                    }}
                  >
                    <div
                      className="dot"
                      style={{
                        backgroundColor:
                          order?.transferSettlement[0]?.status === "Pending"
                            ? "#EA4335"
                            : "#FF9900",
                      }}
                    ></div>
                    <Typography
                      className="Card-price"
                      fontSize={contentFontSize_16}
                      color={"black"}
                      sx={{ fontFamily: "Roboto", fontWeight: "600" }}
                    >
                      {order?.transferSettlementStatus}
                    </Typography>
                  </div>
                </Grid>

                <Grid item xs={12} sm={12} md={2} lg={2} xl={2}>
                  <div
                    style={{
                      marginTop: "12px",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <CustomButton
                      width="100%"
                      height="32px"
                      onClick={() => handleViewClick(order)}
                    >
                      <Typography
                        style={{
                          textTransform: "capitalize",
                          marginBottom: "0px",
                        }}
                      >
                        View Transaction
                      </Typography>
                    </CustomButton>
                  </div>
                </Grid>
              </Grid>
            </Box>
          </CustomCard>
        </Grid>
      ))}
      {props?.NotProcessedPaymentData?.data?.listAllNotProcessedSettlements
        ?.hasNext && (
        <div
          style={{
            display: "flex",
            justifyContent: "flex-start",
            marginTop: "20px",
          }}
        >
          <CustomButton onClick={handleLoadMore}>Load More</CustomButton>
        </div>
      )}
    </div>
  );
}

export default PaymentOne;
