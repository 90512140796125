import { gql } from "@apollo/client";

export const GET_STORE_SEARCH = gql`
  query (
    $pagination: PaginationArg
    $sellerEmail: String
    $sellerMobile: String
    $status: String
  ) {
    searchstore(
      pagination: $pagination
      sellerEmail: $sellerEmail
      sellerMobile: $sellerMobile
      status: $status
    ) {
      count
      items {
        id
        storeName
        deliveryType
        storeStatus
        storeLogoUrl
        storeDisplayImageUrl
        storeWorking
        businessType
        businessTypeId
        retailer
        storeCoordinates {
          type
        }
        forAdmin {
          firstName
          lastName
          mobileNumber
          secondaryNumber
          emailAddress
          password
          PANnumber
          GSTnumber
          aadharNumber
        }
        subscription {
          type
          startdate
          amount
        }
      }
      hasNext
    }
  }
`;

export const GET_STORE_DETAILS = gql`
  query ($storeid: ID!) {
    store(storeid: $storeid) {
      id
      fssaiUrl
      licenseUrl
      fssaiNo
      packingCharge
      preparationTime
      deliveryType
      paymentSupported
      deliveryMaxDistance
      openingTime
      storeWorking
      razorPayAccountId
      closingTime
      holidays
      shopAsstTruncatedFlow
      bankDetails {
        bankAccountNumber
        accountHolderName
        IFSCcode
      }
      minAmount
      deliveryDays {
        deliveryDay
        T9amTO12pm
        T12pmTO3pm
        T3pmTO6pm
        T6pmTO9pm
      }
      deliveryCharge {
        kmRange
        charge
      }
      storeCoordinates {
        type
        coordinates
      }
      subscription {
        type
        startdate
        amount
      }
      storeLocation
      storeName
      storeStatus
      storeLogoUrl
      storeDisplayImageUrl
      businessType
      storeManagerFirstName
      businessTypeId
      storeManagerLastName
      storeManagerMobile
      storeManagerPhone
      storeDescription
      storeLocality
      productMarkupPercent
      packingMarkupPercent
      deliveryChargeMarkupPercent
      platformFeePercent
      productGSTPercent
      packingGSTPercent
      deliveryChargeGSTPercent
      platformFeeGSTPercent
      retailer
      forAdmin {
        firstName
        lastName
        mobileNumber
        secondaryNumber
        emailAddress
        password
        PANnumber
        GSTnumber
        aadharNumber
      }
    }
  }
`;
export const GET_RETAILER_DETAIL = gql`
  query ($retailerid: ID!) {
    retailerdetails(retailerid: $retailerid) {
      id
      GSTurl
      aadharUrl
      aadharNumber
    }
  }
`;

export const UPDATE_RETAILER = gql`
  mutation (
    $retailerid: ID!
    $firstName: String
    $lastName: String
    $mobileNumber: String
    $secondaryNumber: String
    $PANnumber: String
    $GSTnumber: String
    $aadharNumber: String
  ) {
    updateretailer(
      retailerid: $retailerid
      firstName: $firstName
      lastName: $lastName
      mobileNumber: $mobileNumber
      secondaryNumber: $secondaryNumber
      PANnumber: $PANnumber
      GSTnumber: $GSTnumber
      aadharNumber: $aadharNumber
    ) {
      id
      firstName
      lastName
      mobileNumber
      secondaryNumber
      emailAddress
      PANnumber
      GSTnumber
      aadharNumber
    }
  }
`;

export const GET_ORDER_SEARCH = gql`
  query (
    $pagination: PaginationArg
    $storeid: ID
    $orderNumber: Float
    $customerMobile: String
    $orderstatus: String
    $dateFrom: String
    $dateTo: String
    $deliveryDateFrom: String
    $deliveryDateTo: String
  ) {
    searchorder(
      pagination: $pagination
      storeid: $storeid
      orderNumber: $orderNumber
      customerMobile: $customerMobile
      orderstatus: $orderstatus
      dateFrom: $dateFrom
      dateTo: $dateTo
      deliveryDateFrom: $deliveryDateFrom
      deliveryDateTo: $deliveryDateTo
    ) {
      items {
        id
        orderNumber
        storeid {
          storeName
        }
        deliveryDate
        deliveryTime
        mobileNumber
        customerName
        lastStatus
      }
      hasNext
    }
  }
`;
export const GET_ORDER_SEARCH_1 = gql`
  query (
    $pagination: PaginationArg
    $storeid: ID
    $customerMobile: String
    $orderstatus: String
    $dateFrom: String
    $dateTo: String
    $deliveryDateFrom: String
    $deliveryDateTo: String
  ) {
    searchorder(
      pagination: $pagination
      storeid: $storeid
      customerMobile: $customerMobile
      orderstatus: $orderstatus
      dateFrom: $dateFrom
      dateTo: $dateTo
      deliveryDateFrom: $deliveryDateFrom
      deliveryDateTo: $deliveryDateTo
    ) {
      items {
        id
        orderNumber
        storeid {
          storeName
        }
        deliveryDate
        deliveryTime
        mobileNumber
        customerName
        lastStatus
      }
    }
  }
`;

export const GET_ORDER_DETAILS = gql`
  query ($orderid: ID!) {
    orderdetails(orderid: $orderid) {
      id
      orderNumber
      storeid {
        id
        storeName
        deliveryMaxDistance
        storeCoordinates {
          coordinates
        }
        storeLocation
        storeManagerFirstName
        storeManagerLastName
        storeManagerMobile
        storeStatus
        forAdmin {
          emailAddress
        }
      }
      userid {
        id
        firstName
        lastName
        email
        primaryLatitude
        primaryLongitude
        primaryLocation
        phoneNumber
        address {
          id
          type
          houseNumber
          landmark
          locationDescription
          locationLat
          locationLng
        }
      }
      products {
        shopAssistantQuantity
        productPrice
        markedupProductPrice
        productid {
          id
          productname
          images
          image {
            primary
          }
          desc
          price
          uom
          quantity
        }
        quantity
        price
        productDiscountPrice
        status
      }
      totalPrice
      productCount
      totalPriceUser
      deliveryType
      deliveryAddress
      deliveryDate
      deliveryTime
      deliveryLat
      deliveryLng
      mobileNumber
      customerName
      specialInstructions
      deliveryInstructions
      commentsSeller
      stats {
        status
        created
        createdTime
      }
      lastStatus
      shopAssistantId {
        id
        firstName
        lastName
      }
      deliveryPartnerId {
        id
        firstName
        lastName
      }
      deliveryCharge
      finalBill
      finalBillAmount
      totalPriceDelivery
      paymentOrderId
      razorpayOrderid
      razorpayPaymentId
      razorpaySignature
      refund
      paymentStatus
      replacementStatus
      shoppingTotalPrice
      shoppingTotal
      shoppingDiscountPrice
      shoppingTotalPayable
      shoppingTotalPriceGST
      shoppingPackingCharge
      shoppingPlatformFee
      shoppingPackingChargeGstAmount
      shoppingGST
      isReceivedAmountGreater
      deliveryBy
      transferSettlementStatus
      transferSettlement {
        status
        situation
        created
        createdTime
        timestamp
      }
      amountForSeller
      amountForCustomer
      amountForNearshopz
      amountForNearshopzDeliveryPerson
      amountForExternalDeliveryPerson
      extraAmountByNearshopz
      amountAdjustedManually
      editedAmountForSeller
      editedAmountForNearshopz
      editedAmountForNearshopzDeliveryPerson
      editedAmountForExternalDeliveryPerson
      editedExtraAmountByNearshopz
      type
      paymentActive
      couponId
      couponID {
        id
        type
        name
        couponCode
      }
      discountPrice
      travelTime {
        text
        value
      }
      travelDistance {
        text
        value
      }
      estimatedDeliveryTime
      GST
      packingCharge
      handlingCharge
      deliveryTip
      total
      totalPayable
      version
      preparationTime
      packingImages
      razorPayFeesAndTax
      transfers {
        id
        amount
        fees
        tax
        amount_reversed
        transfer_status
        settlement_status
        transferTo
      }
      forceCompleteSettlement
      sellerReimbursement
      editedSellerReimbursement
      totalPriceGST
      totalPriceGSTPercent
      totalPriceMarkupPercent
      deliveryChargeGstAmount
      deliveryChargeGstPercent
      markedupDeliveryChargePercent
      packingChargeGstAmount
      packingChargeGstPercent
      markedupPackingChargePercent
      platformFee
      platformFeeGstAmount
      platformFeeGstPercent
      platformFeePercent
      totalPriceWithoutMarkup
      deliveryChargeWithoutMarkup
      packingChargeWithoutMarkup
      shoppingTotalPriceWithoutMarkup
      shoppingPackingChargeWithoutMarkup
    }
  }
`;

export const GET_ACTIVE_ORDERS = gql`
  query ($pagination: PaginationArg, $orderstatus: String) {
    activeOrders(pagination: $pagination, orderstatus: $orderstatus) {
      hasNext
      items {
        id
        orderid {
          id
          orderNumber
          stats {
            status
            created
            timestamp
          }
          shopAssistantId {
            id
            firstName
            lastName
            phoneNumber
          }
          storeid {
            storeName
          }
          deliveryDate
          deliveryTime
        }
        storeid {
          storeName
        }
        scheduledDateAndTime
        scheduledMinutes
        lastStatus
        updatedAt
        counter
      }
    }
  }
`;

export const SHOP_ASSISTANT_DETAILS = gql`
  query ($shopAssistantId: ID) {
    viewParticularShopAssistant(shopAssistantId: $shopAssistantId) {
      id
      firstName
      lastName
      phoneNumber
      storeid
      storeName
    }
  }
`;

export const GET_STORE_SEARCH_BY_NAME = gql`
  query ($searchKey: String) {
    searchStoreName(searchKey: $searchKey) {
      id
      storeName
      storeLocation
      storeStatus
      businessType
      businessTypeId
      retailer
      storeWorking
      forAdmin {
        firstName
        lastName
        mobileNumber
        emailAddress
        aadharNumber
      }
    }
  }
`;

export const ORDER_SEARCH = gql`
  query (
    $pagination: PaginationArg
    $storeid: ID
    $orderNumber: Float
    $customerMobile: String
    $orderstatus: String
    $dateFrom: String
    $dateTo: String
    $deliveryDateFrom: String
    $deliveryDateTo: String
  ) {
    searchorder(
      pagination: $pagination
      storeid: $storeid
      orderNumber: $orderNumber
      customerMobile: $customerMobile
      orderstatus: $orderstatus
      dateFrom: $dateFrom
      dateTo: $dateTo
      deliveryDateFrom: $deliveryDateFrom
      deliveryDateTo: $deliveryDateTo
    ) {
      items {
        id

        orderNumber

        storeid {
          id
        }

        totalPrice

        productCount

        totalPriceUser

        deliveryType

        deliveryAddress

        deliveryDate

        deliveryTime

        deliveryLat

        deliveryLng

        mobileNumber

        customerName

        specialInstructions

        deliveryInstructions

        commentsSeller

        lastStatus

        deliveryCharge

        finalBill

        finalBillAmount

        totalPriceDelivery

        paymentOrderId

        razorpayOrderid

        razorpayPaymentId

        razorpaySignature

        refund

        refundId

        refundAmount

        refundStatus

        refundList {
          refundId
          refundAmount
        }

        paymentStatus

        replacementStatus

        shoppingTotalPrice

        shoppingTotal

        shoppingDiscountPrice

        shoppingTotalPayable

        shoppingTotalPriceGST

        shoppingPackingCharge

        shoppingPackingChargeGstAmount

        shoppingGST

        shoppingPlatformFee

        shoppingPlatformFeeGSTAmount

        isReceivedAmountGreater

        deliveryBy

        transferSettlementStatus

        amountForSeller

        amountForCustomer

        amountForNearshopz

        amountForNearshopzDeliveryPerson

        amountForExternalDeliveryPerson

        extraAmountByNearshopz

        amountAdjustedManually

        editedAmountForSeller

        editedAmountForNearshopz

        editedAmountForNearshopzDeliveryPerson

        editedAmountForExternalDeliveryPerson

        editedExtraAmountByNearshopz

        type

        paymentActive

        couponId

        couponID {
          id
        }

        discountPrice

        estimatedDeliveryTime

        GST

        packingCharge

        handlingCharge

        deliveryTip

        total

        totalPayable

        version

        preparationTime

        razorPayFeesAndTax

        editedRazorPayFeesAndTax

        transfers {
          id
          amount
          fees
          tax
          amount_reversed
        }

        forceCompleteSettlement

        sellerReimbursement

        editedSellerReimbursement

        totalPriceGST

        totalPriceGSTPercent

        deliveryChargeGstAmount

        deliveryChargeGstPercent

        markedupDeliveryChargePercent

        packingChargeGstAmount

        packingChargeGstPercent

        markedupPackingChargePercent

        platformFee

        platformFeeGstAmount

        platformFeeGstPercent

        platformFeePercent
        totalPriceMarkupPercent
        shoppingTotalPriceWithoutMarkup
        deliveryChargeWithoutMarkup
        packingChargeWithoutMarkup
        shoppingTotalPriceWithoutMarkup
        shoppingPackingChargeWithoutMarkup
      }
      hasNext
    }
  }
`;
