import {
  Alert,
  Checkbox,
  DialogContent,
  FormControlLabel,
  FormGroup,
  Grid,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { Navigate, useLocation } from "react-router-dom";
import "./OutForDelivery.css";
import Direction from "../Utilities/Icon/Direction.svg";
import Phone from "../Utilities/Icon/Phone.svg";
import ReadyForLine from "../Utilities/Icon/ReadyForLine.svg";
import LineInReadyForDel from "../Utilities/Icon/LineInReadyForDel.svg";
import UploadImage from "../Utilities/Icon/UploadImage.svg";
import Camera from "../Utilities/Icon/Camera.svg";
import BlueClock from "../Utilities/Icon/BlueClock.svg";
import CustomButton from "../CommonComponents/button";
import Payment from "../Utilities/Icon/Payment.svg";
import Pointer from "../Utilities/Icon/Pointer.svg";
import lines from "../Utilities/Icon/lines.svg";
import Scanner from "../Utilities/Icon/Scanner.svg";
import DirectionSub from "../Utilities/Icon/DirectionSub.svg";
import deliver from "../utils/Icon/scooter.svg";
import { IconButton, Menu, MenuItem } from "@mui/material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import {
  VerticalTimeline,
  VerticalTimelineElement,
} from "react-vertical-timeline-component";
//import "react-vertical-timeline-component/style.min.css";
import {
  contentFontSize_16,
  contentFontSize_18,
  contentFontSize_20,
  contentFontsize_14,
  HeaderNavigationFontSize_20_xs_13,
  contentFontSize_22,
  contentFontSize_18_xs_13,
  contentFontSize_20_xs_18,
  contentFontsize_17,
  HeaderFontSize_24,
} from "../Typography";
import CustomCard from "../CommonComponents/card";
import Timeline from "@mui/lab/Timeline";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineDot from "@mui/lab/TimelineDot";
import { message } from "antd";
import OrderHistory from "./OrderHistory";
import { Stack } from "react-bootstrap";
import GoogleMapOrder from "./GoogleMapOrder";
import CustomDialog from "../CommonComponents/dialog";
import CustomTextField from "../CommonComponents/input";
import { InputAdornment } from "@material-ui/core";

function OutForDelivery(props) {
  const location = useLocation();
  const orderid = localStorage.getItem("orderID");
  console.log("orderId:", orderid);

  // useEffect(() => {
  //   props.getOrderDetails(orderid);
  //   console.log("three", props.getOrderDetails(orderid));
  // }, []);

  const OutForDeliveryDetails =
    props && props.ChangetoDelivery
      ? props.ChangetoDelivery.changeToOutForDelivery
      : props?.OrderDetails?.orderdetails;

  // const nav = useNavigate();
  function handleClickOne() {
    // nav("/home/MyOrders/ReadyForDelivery");
  }
  function handleClickTwo() {
    // nav("/home/MyOrders");
  }
  const [direction, setDirection] = useState(false);
  function handleDirection() {
    setDirection(true);
  }
  const dataItem = JSON.parse(localStorage.getItem("selectedItems"));
  const detailsReady =
    props && props.ChangetoDelivery
      ? props.ChangetoDelivery.changeToOutForDelivery
      : props?.OrderDetails?.orderdetails;

  // const totalProductPrice = OutForDeliveryDetails
  //   ? OutForDeliveryDetails?.products?.reduce(
  //       (total, item) => total + item.shopAssistantQuantity * item.productPrice,
  //       0
  //     )
  //   : 0;
  const totalProductPrice = detailsReady
    ? detailsReady.products.reduce(
        (total, item) => total + item.shopAssistantQuantity * item.productPrice,
        0
      )
    : OutForDeliveryDetails
    ? OutForDeliveryDetails.products.reduce(
        (total, item) => total + item.shopAssistantQuantity * item.productPrice,
        0
      )
    : 0;

  const valueLabelMap = {
    1: "Seller",
    2: "NearShopz",
    3: "External Delivery Person",
  };

  // Initialize selectedValue as null and update it when activeOrderDetails.deliveryBy changes
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedValue, setSelectedValue] = useState(null);

  console.log("Selected value on load:", selectedValue);

  useEffect(() => {
    // Update selectedValue when activeOrderDetails.deliveryBy changes
    if (detailsReady && detailsReady?.deliveryBy) {
      setSelectedValue(detailsReady?.deliveryBy);
    }
  }, [detailsReady]);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose1 = () => {
    setAnchorEl(null);
  };

  const handleMenuItemClick = (value) => {
    setSelectedValue(value);
    setAnchorEl(null);
    props.updateDeliveryBy({
      orderid: OutForDeliveryDetails.id,
      deliveryBy: value,
    });
    console.log("Selected:", value, "Order ID:", OutForDeliveryDetails.id);
  };

  // Determine the label to display based on selectedValue
  const displayedLabel = valueLabelMap[selectedValue] || "Seller";

  const deliveryCharge = OutForDeliveryDetails?.deliveryCharge;
  const packingCharge =
    OutForDeliveryDetails?.packingCharge +
    OutForDeliveryDetails?.handlingCharge;
  const discountPrice = OutForDeliveryDetails?.discountPrice;

  // Calculate the total charges
  const totalCharges = deliveryCharge + packingCharge - discountPrice;

  // Calculate the grand total
  const grandTotal = totalProductPrice + totalCharges;
  const [deliveryCompleted, setDeliveryCompleted] = useState(true);

  const handleboxChange = () => {
    setDeliveryCompleted(!deliveryCompleted);
  };
  const OrderHistorybuttonStateRef = useRef(false);
  // const [OrderHistory, setOrderHistory] = useState([]);
  // function handleDelivered() {
  //   // const dataHistory = JSON.parse(localStorage.getItem("orderCardDetails"));
  //   // OrderHistorybuttonStateRef.current = !OrderHistorybuttonStateRef;
  //   // dataHistory.buttonState = OrderHistorybuttonStateRef.current;
  //   // const orderId = dataHistory.orderId;

  //   // const OrderHistory = JSON.parse(localStorage.getItem("OrderHistory")) || [];

  //   // // Check if the orderId is already present in acceptedOrders
  //   // const isOrderHistory = OrderHistory.some(
  //   //   (order) => order.orderId === orderId
  //   // );

  //   // if (!isOrderHistory) {
  //   //   // If the order is not already accepted, update both the local storage and state
  //   //   const updatedOrderHistory = [...OrderHistory, dataHistory];
  //   //   localStorage.setItem("OrderHistory", JSON.stringify(updatedOrderHistory));

  //   //   // Update the acceptedOrders state with the new order
  //   //   setOrderHistory(updatedOrderHistory);
  //   // } else {
  //   //   // The order is already accepted, you can handle this case if needed
  //   //   alert("This order has already been accepted.");
  //   // }

  //   // // Update the orderCardDetails in local storage with the new order
  //   // localStorage.setItem("orderCardDetails", JSON.stringify(dataItem));
  //   // console.log(dataItem);
  //   if(checked && deliveryCompleted){
  //     message.error({
  //       content: "Status Changed to Completed",
  //       className: "custom-class",
  //       style: {
  //         marginTop: "20vh",
  //         marginLeft: "60vh",
  //       },
  //     });
  //     if(checked && deliveryCompleted ){
  //       nav("/home/MyOrders/OrderHistory");
  //       }
  //   }else{
  //     message.error({
  //       content: "Please choose the payment method",
  //       className: "custom-class",
  //       style: {
  //         marginTop: "20vh",
  //         marginLeft: "60vh",
  //       },
  //     })
  //   }
  //   const status = "Completed";
  //   props.ChangetoCompletedStatus({
  //     orderid: orderid,
  //     stats: { status: status },
  //   });

  // }
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("md"));
  const matchessm = useMediaQuery(theme.breakpoints.down("sm"));
  const matcheslg = useMediaQuery(theme.breakpoints.down("lg"));

  const upXlmatch = useMediaQuery(theme.breakpoints.up("xl"));
  const xlmatch = useMediaQuery(theme.breakpoints.down("xl"));
  const lgmatch = useMediaQuery(theme.breakpoints.down("lg"));
  const mdmatch = useMediaQuery(theme.breakpoints.down("md"));
  const smmatch = useMediaQuery(theme.breakpoints.down("sm"));
  const xsmatch = useMediaQuery(theme.breakpoints.down("xs"));

  const [showScanner, setShowScanner] = useState(false);
  const [showCollected, setShowCollected] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);
  const handleCheckboxChange = (event) => {
    const isChecked = event.target.checked;
    setShowScanner(isChecked);
    setShowCollected(!isChecked);
  };
  const handleChange = (event) => {
    const isChecked = event.target.checked;
    setShowCollected(isChecked);
    setShowScanner(!isChecked);
  };

  const [checked, setChecked] = useState();
  const handleCheckbox = (event) => {
    const checked = event.target.checked;
    setChecked(checked);
    console.log("c", checked);
  };
  const [showCompleted, setShowCompleted] = useState(false);
  const [Completed, setCompleted] = useState(false);
  // function handleDelivereds() {
  //   const status = "Completed";
  //   props.ChangetoCompletedStatus({
  //     orderid: orderid,
  //     stats: { status: status },
  //   });
  //   setShowCompleted(true);
  // }
  function handleDelivereds() {
    if (!deliveryCompleted) {
      const status = "Completed";
      props.ChangetoCompletedStatus({
        orderid: orderid,
        stats: { status: status },
      });
      setShowCompleted(true);
    } else {
      setCompleted(true);
    }
  }
  function handleDelivered() {
    // setShowCompleted(true);
    // const dataHistory = JSON.parse(localStorage.getItem("orderCardDetails"));
    // OrderHistorybuttonStateRef.current = !OrderHistorybuttonStateRef;
    // dataHistory.buttonState = OrderHistorybuttonStateRef.current;
    // const orderId = dataHistory.orderId;

    // const OrderHistory = JSON.parse(localStorage.getItem("OrderHistory")) || [];

    // // Check if the orderId is already present in acceptedOrders
    // const isOrderHistory = OrderHistory.some(
    //   (order) => order.orderId === orderId
    // );

    // if (!isOrderHistory) {
    //   // If the order is not already accepted, update both the local storage and state
    //   const updatedOrderHistory = [...OrderHistory, dataHistory];
    //   localStorage.setItem("OrderHistory", JSON.stringify(updatedOrderHistory));

    //   // Update the acceptedOrders state with the new order
    //   setOrderHistory(updatedOrderHistory);
    // } else {
    //   // The order is already accepted, you can handle this case if needed
    //   alert("This order has already been accepted.");
    // }

    // // Update the orderCardDetails in local storage with the new order
    // localStorage.setItem("orderCardDetails", JSON.stringify(dataItem));
    // console.log(dataItem);

    if (checked && !deliveryCompleted) {
      setShowCompleted(true);
      const status = "Completed";
      props.ChangetoCompletedStatus({
        orderid: orderid,
        stats: { status: status },
      });
      // nav("/home/MyOrders/OrderHistory");
    } else {
      setCompleted(true);
    }
  }
  // const [storedPackingImages, setStoredPackingImages] = useState(() => {
  //   // Parse the images from localStorage
  //   const images = OutForDeliveryDetails.packingImages? OutForDeliveryDetails.packingImages : localStorage.getItem('packingImages')

  // });
  // se
  // console.log("eee", storedPackingImages);
  const [packingImagess, setPackingImages] = useState([]);
  useEffect(() => {
    // Retrieve the images from localStorage
    const storedImages = localStorage.getItem("packingImages");

    if (storedImages) {
      try {
        const parsedImages = JSON.parse(storedImages);
        setPackingImages(parsedImages);
      } catch (error) {
        console.error("Error parsing JSON:", error);
      }
    }
  }, []);

  const imagesToDisplay =
    OutForDeliveryDetails?.packingImages &&
    OutForDeliveryDetails.packingImages.length > 0
      ? OutForDeliveryDetails.packingImages.map((detail) => detail)
      : packingImagess;

  console.log("poda", imagesToDisplay);

  const [share, setShare] = useState(false);
  const [open, setOpen] = useState(false);
  function handleShare() {
    setShare(true);
    setOpen(true);
  }
  const url = localStorage.getItem("googleMapsUrl");

  const [copySuccess, setCopySuccess] = useState(false);

  console.log("the dynamic link", url);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setCopySuccess(false);
  };

  const copyCodeToClipboard = async () => {
    const el = inputRef.current;
    el.select();
    try {
      await navigator.clipboard.writeText(el.value);
      setCopySuccess(true);
    } catch (error) {
      console.error("Failed to copy: ", error);
    }
  };

  const inputRef = React.createRef();

  const destination = {
    lat: Number(OutForDeliveryDetails?.deliveryLat),
    lng: Number(OutForDeliveryDetails?.deliveryLng),
  };
  const origin = {
    lat:
      Number(
        OutForDeliveryDetails?.storeid?.storeCoordinates?.coordinates[1]
      ) || 10.850516,
    lng:
      Number(
        OutForDeliveryDetails?.storeid?.storeCoordinates?.coordinates[0]
      ) || 76.27108,
  };
  const googleMapsUrl = `https://www.google.com/maps/dir/?api=1&origin=${origin.lat},${origin.lng}&destination=${destination.lat},${destination.lng}&travelmode=driving`;
  localStorage.setItem("googleMapsUrl", googleMapsUrl);

  return (
    <>
      <div style={{ marginLeft: "0px" }}>
        {!showCompleted && !direction && (
          <>
            <div style={{ display: "flex", gap: "50px" }}>
              <div style={{ width: "55%" }}>
                <div style={{ marginTop: "30px" }}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                      <div style={{ display: "flex", flexDirection: "column" }}>
                        <div style={{ display: "flex", gap: "5px" }}>
                          <img
                            src={Direction}
                            style={{ width: "20px", height: "20px" }}
                            alt="Direction Icon"
                          />

                          <div>
                            <p
                              className="nameStyle1"
                              style={{ color: "black" }}
                            >
                              {" "}
                              {OutForDeliveryDetails &&
                                OutForDeliveryDetails.customerName}
                            </p>
                          </div>
                        </div>

                        <div>
                          <p
                            style={{
                              fontWeight: "400",
                              fontFamily: "Roboto",
                              fontSize: "14px",
                              textAlign: "left",
                              color: "black",
                            }}
                          >
                            {OutForDeliveryDetails &&
                              OutForDeliveryDetails?.deliveryAddress}
                            ,
                            {OutForDeliveryDetails &&
                              OutForDeliveryDetails?.deliveryDate}
                            ,
                          </p>
                        </div>
                        <div>
                          <p
                            style={{
                              fontWeight: "400",
                              fontFamily: "Roboto",
                              fontSize: "14px",
                              color: "black",
                            }}
                          >
                            {OutForDeliveryDetails &&
                              OutForDeliveryDetails.deliverytime}
                          </p>
                        </div>
                      </div>
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                      <div
                        style={{
                          display: "flex",
                          gap: "5px",
                          justifyContent: smmatch ? "flex-start" : "flex-end",
                          height: "100%",
                          marginTop: "18px",
                        }}
                      >
                        <img
                          src={Phone}
                          style={{ width: "20px", height: "20px" }}
                          alt="Phone icon"
                        />
                        <span>
                          <p
                            className="numberStyle1"
                            style={{ color: "black" }}
                          >
                            +91 {OutForDeliveryDetails?.userid?.phoneNumber}
                          </p>
                        </span>
                      </div>
                    </Grid>
                  </Grid>
                  <div>
                    <img style={{ width: "100%" }} src={ReadyForLine} />
                  </div>
                  <Grid container spacing={2}>
                    <Grid
                      item
                      xs={12}
                      sm={6}
                      md={6}
                      lg={6}
                      xl={6}
                      sx={{ marginTop: "25px" }}
                    >
                      <div
                        style={{
                          display: "flex",
                          gap: "10px",
                          overflow: "hidden",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            height: "80px",
                          }}
                        >
                          <div style={{ position: "relative" }}>
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                height: "100%",
                                justifyContent: "space-between",
                              }}
                            >
                              <div
                                style={{
                                  background: "#85BE49",
                                  height: "10px",
                                  width: "10px",
                                  borderRadius: "50%",
                                }}
                              ></div>
                              <div
                                style={{
                                  background: "#85BE49",
                                  height: "10px",
                                  width: "10px",
                                  borderRadius: "50%",
                                }}
                              ></div>
                            </div>
                            <div className="connecting-line"></div>
                            {/* </div> */}
                          </div>
                        </div>

                        <div>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              height: "100%",
                              justifyContent: "space-between",
                              lineHeight: "1",
                            }}
                          >
                            <div
                              fontSize={contentFontSize_18}
                              style={{
                                fontWeight: "800",
                                fontFamily: "Roboto",
                                color: "#000000",
                              }}
                            >
                              Outlet
                            </div>
                            <div
                              fontSize={contentFontSize_18}
                              style={{
                                fontWeight: "800",
                                fontFamily: "Roboto",
                                color: "#000000",
                              }}
                            >
                              {/* 35 mins */}
                              {OutForDeliveryDetails?.travelTime?.text}
                            </div>
                          </div>
                        </div>
                        <div>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              height: "100%",
                              justifyContent: "space-between",
                              lineHeight: "1",
                            }}
                          >
                            <div
                              style={{
                                fontSize: "14px",
                                color: "#8B8989",
                                fontWeight: "500",
                              }}
                            >
                              {OutForDeliveryDetails?.storeid?.storeName}
                            </div>
                            <div
                              style={{
                                fontSize: "14px",
                                color: "#8B8989",
                                fontWeight: "500",
                              }}
                            >
                              {/* 3.6 kilometers */}
                              {OutForDeliveryDetails?.travelDistance?.text}
                            </div>
                          </div>
                        </div>
                      </div>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={6}
                      md={6}
                      lg={6}
                      xl={6}
                      //sx={{ margin: "1px -20px 44px 47px" }}
                    >
                      <div
                        style={{
                          display: "flex",
                          gap: "30px",
                          height: "100%",
                          justifyContent: smmatch ? "flex-start" : "flex-end",
                          alignItems: "center",
                          marginTop: smmatch ? "20px" : "0px",
                        }}
                      >
                        <div>
                          <CustomButton
                            width="120px"
                            height="45px"
                            variant="outlined"
                            color="#85BE49"
                            borderColor="#85BE49"
                            background="none"
                            hasIcon={true}
                            iconPosition="startIcon"
                            onClick={handleDirection}
                          >
                            <img
                              src={Direction}
                              style={{
                                width: "15px",
                                height: "15px",
                                marginRight: "10px",
                              }}
                            />
                            Direction
                          </CustomButton>
                        </div>

                        <div onClick={handleShare}>
                          <img
                            onClick={handleShare}
                            src={DirectionSub}
                            style={{
                              width: "25px",
                              height: "33px",
                              marginRight: "24px",
                            }}
                          />
                        </div>
                      </div>
                    </Grid>
                  </Grid>
                  {share && (
                    <CustomDialog
                      width="600px"
                      height="200px"
                      borderRadius="20px"
                      open={open}
                      onClose={handleClose}
                      titleAlign="center"
                      // title="Share URL"
                      top={"15px"}
                      right={"20px"}
                      // showCloseButton={false}
                    >
                      <Typography
                        style={{
                          color: "#170F49",
                          textAlign: "center",
                          fontFamily: "Roboto",
                          fontWeight: "700",
                        }}
                        className="head-font"
                        fontSize={HeaderFontSize_24}
                      >
                        Share URL
                      </Typography>
                      <DialogContent>
                        <div>
                          <CustomTextField
                            width="100%"
                            value={url}
                            inputRef={inputRef}
                            endAdornment={
                              <InputAdornment position="end">
                                <div>
                                  <CustomButton
                                    onClick={() => copyCodeToClipboard()}
                                  >
                                    <Typography fontSize={contentFontSize_16}>
                                      Copy Url
                                    </Typography>
                                  </CustomButton>
                                </div>
                              </InputAdornment>
                            }
                          ></CustomTextField>
                          <div
                            style={{
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            {copySuccess ? (
                              <div
                                style={{ color: "#85BE49", paddingTop: "6px" }}
                              >
                                <Typography fontSize={contentFontSize_16}>
                                  Copied to Clipboard
                                </Typography>
                              </div>
                            ) : null}
                          </div>
                        </div>
                      </DialogContent>
                    </CustomDialog>
                  )}
                </div>

                <div className="outfordeliveryBox">
                  <div>
                    <p
                      fontSize={contentFontsize_17}
                      style={{ marginBottom: "0px", color: "black" }}
                      className="textStyleOut"
                    >
                      #
                      {OutForDeliveryDetails &&
                        OutForDeliveryDetails.orderNumber}{" "}
                      - Scheduled on{" "}
                      {OutForDeliveryDetails &&
                        OutForDeliveryDetails.deliveryDate}
                      ,{" "}
                      {OutForDeliveryDetails &&
                        OutForDeliveryDetails.deliveryTime}
                    </p>
                  </div>
                </div>

                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "10px",
                    marginTop: "20px",
                    padding: smmatch ? "10px 10px" : "0px 50px 0px 0px",
                  }}
                >
                  {(detailsReady
                    ? detailsReady.products
                    : OutForDeliveryDetails.products
                  )?.map((item) => (
                    <div style={{ display: "flex" }} key={item.id}>
                      {console.log("item received", item)}
                      <div style={{ flex: "1" }}>
                        <div style={{ display: "flex", gap: "60px" }}>
                          <div className="productDetailsOut">
                            <img
                              style={{
                                width: matchessm ? "15px" : "20px",
                                height: matchessm ? "15px" : "20px",
                              }}
                              src={Pointer}
                              alt={item?.name}
                            />
                          </div>

                          <div>
                            <p
                              fontSize={contentFontsize_17}
                              className="productDetailsOut"
                              style={{ textAlign: "left", color: "black" }}
                            >
                              {item?.productid?.productname}
                              <br />
                              <p
                                fontSize={contentFontsize_14}
                                style={{
                                  fontWeight: "400",
                                  fontFamily: "Roboto",
                                  textAlign: "left",
                                  color: "black",
                                }}
                              >
                                {item.shopAssistantQuantity}X ₹
                                {item?.markedupProductPrice?.toFixed(2)}
                              </p>
                            </p>
                          </div>
                        </div>
                      </div>
                      <div style={{ flex: "1" }}>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "flex-end",
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              gap: "1px",
                            }}
                          >
                            <p
                              className="productDetailsOut"
                              fontSize={contentFontsize_17}
                              style={{ color: "black" }}
                            >
                              ₹{" "}
                              {(
                                item?.shopAssistantQuantity *
                                item?.markedupProductPrice
                              )?.toFixed(2)}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
                <Grid container>
                  <Grid
                    item
                    xs={12}
                    md={12}
                    lg={12}
                    sm={12}
                    sx={{ marginTop: "20px" }}
                  >
                    <img
                      style={{ width: "100%" }}
                      src={LineInReadyForDel}
                      alt="LineInReadyForDel"
                    />
                  </Grid>
                </Grid>

                <div
                  style={{
                    padding: smmatch ? "10px 10px" : "0px 50px 0px 75px",
                  }}
                >
                  <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <p
                      fontSize={contentFontSize_16}
                      className="productDetails1"
                    >
                      Item Total :
                    </p>
                    {/* </Grid> */}
                    {/* <Grid item xs={4} md={3} lg={3} sm={3} xl={3}>  */}
                    <p
                      className="productDetails1"
                      fontSize={contentFontSize_16}
                    >
                      ₹{" "}
                      {OutForDeliveryDetails?.shoppingTotalPrice != null
                        ? OutForDeliveryDetails.shoppingTotalPrice.toFixed(2)
                        : OutForDeliveryDetails?.totalPrice != null
                        ? OutForDeliveryDetails.totalPrice.toFixed(2)
                        : "0.00"}
                    </p>
                  </div>
                  <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <p
                      fontSize={contentFontSize_16}
                      className="productDetails1"
                    >
                      Partner Delivery Fee:
                    </p>
                    {/* </Grid> */}

                    {/* <Grid item xs={4} md={3} lg={3} sm={3} xl={3} > */}
                    <p
                      className="productDetails1"
                      fontSize={contentFontSize_16}
                      // sx={{marginLeft:"15px",paddingLeft:"-15px"}}
                    >
                      ₹ {(OutForDeliveryDetails.deliveryCharge ?? 0).toFixed(2)}
                    </p>
                  </div>
                  <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <p
                      fontSize={contentFontSize_16}
                      className="productDetails1"
                    >
                      GST :
                    </p>
                    {/* </Grid> */}

                    {/* <Grid item xs={4} md={3} lg={3} sm={3} xl={3} > */}
                    <p
                      className="productDetails1"
                      fontSize={contentFontSize_16}
                      // sx={{marginLeft:"15px",paddingLeft:"-15px"}}
                    >
                      ₹{" "}
                      {OutForDeliveryDetails?.shoppingGST != null
                        ? parseFloat(OutForDeliveryDetails.shoppingGST).toFixed(
                            2
                          )
                        : OutForDeliveryDetails?.GST != null
                        ? parseFloat(OutForDeliveryDetails.GST).toFixed(2)
                        : "0.00"}
                    </p>
                  </div>
                  <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <p
                      fontSize={contentFontSize_16}
                      className="productDetails1"
                    >
                      Packing & Handling Charges :
                    </p>
                    {/* </Grid> */}

                    {/* <Grid item xs={4} md={3} lg={3} sm={3} xl={3} > */}
                    <p
                      className="productDetails1"
                      fontSize={contentFontSize_16}
                      // sx={{marginLeft:"15px",paddingLeft:"-15px"}}
                    >
                      ₹{" "}
                      {OutForDeliveryDetails?.shoppingPackingCharge != null &&
                      OutForDeliveryDetails?.handlingCharge != null
                        ? (
                            OutForDeliveryDetails.shoppingPackingCharge +
                            OutForDeliveryDetails.handlingCharge
                          ).toFixed(2)
                        : OutForDeliveryDetails?.packingCharge != null &&
                          OutForDeliveryDetails?.handlingCharge != null
                        ? (
                            OutForDeliveryDetails.packingCharge +
                            OutForDeliveryDetails.handlingCharge
                          ).toFixed(2)
                        : "0.00"}
                    </p>
                  </div>
                  <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <p
                      fontSize={contentFontSize_16}
                      className="productDetails1"
                    >
                      Tip For Delivery Partner:
                    </p>
                    {/* </Grid> */}

                    {/* <Grid item xs={4} md={3} lg={3} sm={3} xl={3} > */}
                    <p
                      className="productDetails1"
                      fontSize={contentFontSize_16}
                      // sx={{marginLeft:"15px",paddingLeft:"-15px"}}
                    >
                      ₹{" "}
                      {OutForDeliveryDetails?.deliveryTip != null
                        ? OutForDeliveryDetails.deliveryTip.toFixed(2)
                        : "0.00"}
                    </p>
                  </div>

                  <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <p
                      fontSize={contentFontSize_16}
                      className="productDetails1"
                    >
                      Platform Fee :
                    </p>
                    {/* </Grid> */}

                    {/* <Grid item xs={4} md={3} lg={3} sm={3} xl={3} > */}
                    <p
                      className="productDetails1"
                      fontSize={contentFontSize_16}
                      // sx={{marginLeft:"15px",paddingLeft:"-15px"}}
                    >
                      ₹{" "}
                      {OutForDeliveryDetails?.shoppingPlatformFee != null
                        ? parseFloat(
                            Number(OutForDeliveryDetails.shoppingPlatformFee)
                          ).toFixed(2)
                        : OutForDeliveryDetails?.platformFee != null
                        ? parseFloat(
                            Number(OutForDeliveryDetails.platformFee)
                          ).toFixed(2)
                        : "0.00"}
                    </p>
                  </div>
                  <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <p
                      fontSize={contentFontSize_16}
                      className="productDetails1"
                    >
                      Total :
                    </p>
                    {/* </Grid> */}

                    {/* <Grid item xs={4} md={3} lg={3} sm={3} xl={3} > */}
                    <p
                      className="productDetails1"
                      fontSize={contentFontSize_16}
                      // sx={{marginLeft:"15px",paddingLeft:"-15px"}}
                    >
                      ₹{" "}
                      {OutForDeliveryDetails?.shoppingTotal != null
                        ? parseFloat(
                            OutForDeliveryDetails.shoppingTotal
                          ).toFixed(2)
                        : OutForDeliveryDetails?.total != null
                        ? parseFloat(OutForDeliveryDetails.total).toFixed(2)
                        : "0.00"}
                    </p>
                  </div>

                  {(OutForDeliveryDetails.discountPrice &&
                    OutForDeliveryDetails.discountPrice !== null) ||
                  OutForDeliveryDetails?.couponID?.type === 4 ? (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <p
                        fontSize={contentFontSize_16}
                        style={{ color: "rgba(45, 147, 251, 1)" }}
                        className="productDetails1"
                      >
                        Coupon - ({OutForDeliveryDetails?.couponID?.couponCode})
                      </p>
                      {/* </Grid> */}

                      {/* <Grid item xs={4} md={3} lg={3} sm={3} xl={3}> */}
                      <p
                        className="productDetails1"
                        style={{ color: "rgba(45, 147, 251, 1)" }}
                        fontSize={contentFontSize_16}
                      >
                        -₹{" "}
                        {OutForDeliveryDetails?.shoppingDiscountPrice !==
                          null &&
                        OutForDeliveryDetails.shoppingDiscountPrice !==
                          undefined
                          ? parseFloat(
                              Number(
                                OutForDeliveryDetails.shoppingDiscountPrice
                              )
                            ).toFixed(2)
                          : OutForDeliveryDetails.discountPrice !== null &&
                            OutForDeliveryDetails.discountPrice !== undefined
                          ? parseFloat(
                              Number(OutForDeliveryDetails.discountPrice)
                            ).toFixed(2)
                          : "0.00"}{" "}
                      </p>
                    </div>
                  ) : null}
                </div>

                <Grid container>
                  <Grid item xs={12} md={12} lg={12} sm={12}>
                    <img
                      style={{ width: "100%" }}
                      src={LineInReadyForDel}
                      alt="LineInReadyForDel"
                    />
                  </Grid>
                </Grid>
                <div
                  style={{
                    padding: smmatch ? "10px 10px" : "0px 50px 0px 75px",
                  }}
                >
                  <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <p
                      fontSize={contentFontSize_20_xs_18}
                      style={{
                        fontWeight: "700",
                        fontFamily: "Roboto",
                        color: "black",
                        marginTop: "15px",
                      }}
                    >
                      Total Payable :
                    </p>
                    {/* </Grid>
  
                      <Grid item xs={4} md={3} lg={3} sm={3} xl={3}> */}
                    <p
                      style={{
                        fontWeight: "700",
                        fontFamily: "Roboto",
                        color: "black",
                        marginTop: "15px",
                      }}
                      fontSize={contentFontSize_16}
                    >
                      ₹{" "}
                      {OutForDeliveryDetails?.shoppingTotalPayable != null
                        ? parseFloat(
                            Number(OutForDeliveryDetails.shoppingTotalPayable)
                          ).toFixed(2)
                        : OutForDeliveryDetails?.totalPayable != null
                        ? parseFloat(
                            Number(OutForDeliveryDetails.totalPayable)
                          ).toFixed(2)
                        : "0.00"}
                    </p>
                  </div>
                </div>
                <Grid container>
                  <Grid
                    item
                    xs={12}
                    md={12}
                    lg={12}
                    sm={12}
                    sx={{ marginTop: "0px" }}
                  >
                    <img
                      style={{ width: "100%" }}
                      src={LineInReadyForDel}
                      alt="LineInReadyForDel"
                    />
                  </Grid>
                </Grid>
                {/* <div style={{ marginTop: "20px" }}>
                  <Typography
                    style={{
                      fontWeight: "600",
                      textAlign: "left",
                      color: "black",
                    }}
                    fontSize={contentFontSize_20}
                  >
                    Upload Product Images
                    <span style={{ color: "#EA4335" }}>*</span>
                  </Typography>
                </div>
                <div style={{ textAlign: "left" }}>
                  <img style={{ marginTop: "20px" }} src={UploadImage} />
                  <img
                    style={{ marginTop: "20px", marginLeft: "20px" }}
                    src={Camera}
                  />
                </div> */}
                <div style={{ marginTop: "20px" }}>
                  <Typography
                    style={{
                      fontWeight: "600",
                      textAlign: "left",
                      color: "black",
                    }}
                    fontSize={contentFontSize_20}
                  >
                    Uploaded Product Images
                    <span style={{ color: "#EA4335" }}>*</span>
                  </Typography>
                </div>
                <div
                  style={{
                    textAlign: "left",
                    display: "flex",
                    gap: "20px",
                    flexDirection: "row",
                  }}
                >
                  {imagesToDisplay?.map((imageUrl, index) => (
                    <img
                      key={index} // Adding a key prop for each image to help React identify them uniquely
                      style={{
                        marginTop: "20px",
                        width: "100px",
                        height: "100px",
                      }}
                      src={imageUrl}
                      alt={`Image ${index}`} // Providing alt text for accessibility
                    />
                  ))}
                </div>
                {/* {deliveryCompleted ? ( */}
                {OutForDeliveryDetails.paymentStatus === 0 && (
                  <>
                    <div style={{ marginTop: "20px" }}>
                      <Typography
                        sx={{
                          color: "#000000",
                          fontWeight: "600",
                          fontFamily: "Roboto",
                          textAlign: "left",
                        }}
                        fontSize={contentFontSize_20}
                      >
                        {/* <img style={{ width: "20px", height: "20px" }}, src={Payment} /> */}
                        Payment Status
                      </Typography>
                    </div>

                    <div style={{ marginTop: "20px" }}>
                      <Typography
                        sx={{
                          color: "#1D89E1",
                          fontWeight: "500",
                          fontFamily: "Roboto",
                          textAlign: "left",
                        }}
                        fontSize={contentFontSize_18}
                      >
                        <img
                          style={{
                            width: "18px",
                            height: "22px",
                            marginRight: "10px",
                            marginBottom: "5px",
                          }}
                          src={BlueClock}
                        />
                        Please collect Rs{" "}
                        {OutForDeliveryDetails?.shoppingTotalPayable} from the
                        customer
                        <br />
                        <Typography
                          fontSize={contentFontSize_18}
                          sx={{
                            color: "#646464",
                            marginLeft: "29px",
                            fontWeight: "500",
                            fontFamily: "Roboto",
                            textAlign: "left",
                            marginTop: "15px",
                          }}
                        >
                          Kindly request the customer to choose their preferred
                          payment method from the options provided below.
                        </Typography>
                      </Typography>
                    </div>

                    <div>
                      {Completed && (
                        <Stack>
                          <Alert
                            variant="outlined"
                            severity="warning"
                            sx={{ alignItems: "center", textAlign: "center" }}
                          >
                            Please Choose the payment method
                          </Alert>
                        </Stack>
                      )}
                    </div>
                    <div style={{ display: "flex", marginTop: "20px" }}>
                      <FormGroup
                        style={{
                          marginRight: "16px",
                          marginLeft: "29px",
                          fontFamily: "Roboto",
                          fontWeight: "500",
                        }}
                      >
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={showCollected}
                              onChange={handleChange}
                              sx={{
                                "& .MuiSvgIcon-root": {
                                  color: "#37BD6B",
                                },
                              }}
                            />
                          }
                          label="Cash"
                          sx={{
                            "& .MuiFormControlLabel-label": {
                              fontFamily: "Roboto",
                              fontWeight: "600",
                              fontSize: { contentFontSize_16 },
                            },
                          }}
                          // disabled={showScanner}
                        />
                      </FormGroup>
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={showScanner}
                              onChange={handleCheckboxChange}
                              sx={{
                                "& .MuiSvgIcon-root": {
                                  color: "#37BD6B",
                                },
                              }}
                            />
                          }
                          label="UPI"
                          sx={{
                            "& .MuiFormControlLabel-label": {
                              fontFamily: "Roboto",
                              fontWeight: "600",
                            },
                          }}
                          // disabled={showCollected}
                        />
                      </FormGroup>
                    </div>
                  </>
                )}
              </div>
              <div
                style={{
                  marginTop: "50px",
                  display: "flex",
                  marginLeft: "50px",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    // gap: "10px",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      gap: "10px",
                    }}
                  >
                    <img src={deliver} alt="Amount" />
                    <Typography
                      fontSize={contentFontsize_14}
                      sx={{
                        fontWeight: "600",
                        fontFamily: "Roboto",
                        color: "#13801C",
                      }}
                    >
                      Delivered By
                    </Typography>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      marginLeft: "20px",
                    }}
                  >
                    <Typography fontSize={16} style={{ fontWeight: "400" }}>
                      {displayedLabel}
                    </Typography>
                    <IconButton
                      onClick={handleClick}
                      disableRipple
                      disableFocusRipple
                      sx={{
                        padding: 0,
                        "&:focus": {
                          outline: "none",
                        },
                        "&:hover": {
                          backgroundColor: "transparent",
                        },
                      }}
                    >
                      <ArrowDropDownIcon />
                    </IconButton>

                    <Menu
                      anchorEl={anchorEl}
                      open={Boolean(anchorEl)}
                      onClose={handleClose1}
                    >
                      <MenuItem onClick={() => handleMenuItemClick(1)}>
                        Seller
                      </MenuItem>
                      <MenuItem onClick={() => handleMenuItemClick(2)}>
                        NearShopz
                      </MenuItem>
                      <MenuItem onClick={() => handleMenuItemClick(3)}>
                        External Delivery Person
                      </MenuItem>
                    </Menu>
                  </div>
                </div>
              </div>
            </div>
            {showScanner ? (
              <div className="containers">
                <div className="content" id="paymentContent">
                  <img className="scanner-image" src={Scanner} alt="Scanner" />
                  <Typography className="text" fontSize={contentFontsize_14}>
                    Scan this code to make the payment.
                  </Typography>
                </div>
              </div>
            ) : null}
            {showCollected && (
              <div
                className="containerStyle"
                style={{ background: checked ? "#37BD6B" : "#D9D9D9" }}
              >
                <div
                  style={{
                    marginLeft: matchessm ? "10px" : "30px",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    color: "#FFFFFF",
                  }}
                >
                  <Typography
                    fontSize={contentFontSize_22}
                    sx={{ fontWeight: "700", fontFamily: "Roboto" }}
                  >
                    {" "}
                    Cash Collected
                    <br />
                    <p
                      style={{
                        marginLeft: "0px",
                        marginBottom: "0px",
                        fontWeight: "400",
                        textAlign: "left",
                      }}
                    >
                      ₹{OutForDeliveryDetails?.shoppingTotalPayable}
                    </p>
                  </Typography>
                </div>
                <div style={{ marginLeft: "0px" }}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        sx={{
                          "& .MuiSvgIcon-root": {
                            color: checked ? "white" : "#37BD6B",
                          },
                        }}
                        onChange={handleCheckbox}
                        size={
                          matchessm ? "small" : matches ? "medium" : "large"
                        }
                        //sx={{color:"white"}}
                      />
                    }
                  />
                </div>
              </div>
            )}
            {OutForDeliveryDetails.paymentStatus === 0 ? (
              <>
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <div
                    style={{
                      color: deliveryCompleted ? "black" : "#659B1B",
                      marginTop: "30px",
                    }}
                  >
                    <Checkbox
                      sx={{
                        "& .MuiSvgIcon-root": {
                          color: "#659B1B",
                        },
                      }}
                      //checked={deliveryCompleted}
                      disabled={!checked}
                      onChange={handleboxChange}
                    />
                    Delivery Completed
                  </div>
                </div>

                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    marginTop: "25px",
                  }}
                >
                  <CustomButton
                    width="350px"
                    height="50px"
                    borderRadius="20px"
                    color={deliveryCompleted ? "black" : "white"}
                    background={deliveryCompleted ? "#8B8989" : "#659B1B"}
                    onClick={handleDelivered}
                  >
                    <p
                      style={{
                        textTransform: "capitalize",
                        marginBottom: "0px",
                      }}
                    >
                      Delivered
                    </p>
                  </CustomButton>
                </div>
              </>
            ) : (
              <>
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <div
                    style={{
                      color: deliveryCompleted ? "black" : "#659B1B",
                      marginTop: "30px",
                    }}
                  >
                    <Checkbox
                      sx={{
                        "& .MuiSvgIcon-root": {
                          color: "#659B1B",
                        },
                      }}
                      //checked={deliveryCompleted}
                      onChange={handleboxChange}
                    />
                    Delivery Completed
                  </div>
                </div>

                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    marginTop: "25px",
                  }}
                >
                  <CustomButton
                    width="350px"
                    height="50px"
                    borderRadius="20px"
                    color={deliveryCompleted ? "black" : "white"}
                    background={deliveryCompleted ? "#8B8989" : "#659B1B"}
                    onClick={handleDelivereds}
                  >
                    <p
                      style={{
                        textTransform: "capitalize",
                        marginBottom: "0px",
                      }}
                    >
                      Delivered
                    </p>
                  </CustomButton>
                </div>
              </>
            )}
          </>
        )}
        {showCompleted && <OrderHistory {...props} />}
        {direction && (
          <GoogleMapOrder
            {...props}
            direction={direction}
            setDirection={setDirection}
          />
        )}
      </div>
    </>
  );
}

export default OutForDelivery;
