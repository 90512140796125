import { React, useState, useEffect } from "react";
import {
  contentFontSize_16,
  ButtonFontSize_18,
  HeaderNavigationFontSize_20,
  contentFontsize_14,
  contentFontSize_18,
  contentFontSize_20,
  HeaderFontSize_25,
  HeaderFontSize_24,
  contentFontSize_22,
} from "../Typography/index";
import {
  Grid,
  Box,
  Dialog,
  DialogTitle,
  InputAdornment,
  //makeStyles,
  Paper,
  Typography,
  useMediaQuery,
  useTheme,
  TextField,
  Checkbox,
  FormControlLabel,
} from "@mui/material";
import "./PendingView";
import { useLocation } from "react-router-dom";
import Amount from "../utils/Icon/Amount.svg";
import deliver from "../utils/Icon/scooter.svg";
import payment from "../utils/Icon/paymentmethod.svg";
import edit from "../utils/Icon/Edit1.svg";
import save from "../utils/Icon/savebutton.svg";
import CustomCard from "../CommonComponents/card";
import EditIcon from "../utils/Icon/Edit1.svg";
import EditSve from "../utils/Icon/Save.svg";
import diableEdit from "../utils/Icon/disableEdit.svg";
import CustomButton from "../CommonComponents/button";
import { CheckBox } from "@material-ui/icons";
import TransferPopup from "./TransferPopup";
import Arrow from "../utils/Icon/Arrow1.svg";
import Direction from "../utils/Icon/buttontick.svg";

function PendingView(props) {
  const [checked, setChecked] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const [sellerTransfer, setSellerTransfer] = useState(false);
  const [sellerEdit, setSellerEdit] = useState(false);
  const [nearshopzTransfer, setNearshopzTransfer] = useState(false);
  const [nearshopzEdit, setNearshopzEdit] = useState(false);
  const [externalTransfer, setExternalTransfer] = useState(false);
  const [externalEdit, setExternalEdit] = useState(false);
  const [sellerReimbursement, setSellerReimbursement] = useState(false);
  const [reimbursementEdit, setReimbursementEdit] = useState(false);
  const [transfer, setTransfer] = useState(false);
  const [comment, setComment] = useState(
    props?.UpdateSettelementData?.settlementComment
  );
  const [selectedAccountId, setSelectedAccountId] = useState("");
  const [enableEdit, setEnableEdit] = useState(false);
  const [transfered, setTransfered] = useState();
  const [nearshopzTransfered, setNearshopzTransfered] = useState();
  const [externalTransfered, setExternalTransfered] = useState();
  const [reimbursementTransfered, setReimbursementTransfered] = useState();
  const [transferTo, setTransferTo] = useState("");
  const [failed, setFailed] = useState(false);
  const [failedError, setFailedError] = useState(false);
  const [nearshopzFailed, setNearshopzFailed] = useState(false);
  const [externalFailed, setExternalFailed] = useState(false);
  const [reimbursementFailed, setReimbursementFailed] = useState(false);
  const [firstExternal, setFirstExternal] = useState(false);
  const [selectedTransfer, setSelectedTransfer] = useState("");
  const [firstClick, setFirstClick] = useState(false);
  const [revisedNearshopz, setRevisedNearshopz] = useState();
  const [revisedSellerPrice, setRevisedSellerPrice] = useState();
  const [revisedExternal, setRevisedExternal] = useState();
  const [revisedsellerReimbursement, setRevisedsellerReimbursement] =
    useState();
  const [revisedNearshopzEdit, setRevisedNearshopzEdit] = useState(false);
  const [revisedSellerPriceEdit, setRevisedSellerPriceEdit] = useState(false);
  const [revisedExternalEdit, setRevisedExternalEdit] = useState(false);
  const [revisedsellerReimbursementEdit, setRevisedsellerReimbursementEdit] =
    useState(false);
  const [difference, setDifference] = useState();
  const [sellerFailedTransfer, setSellerFailedTransfer] = useState(false);
  const [nearshopzFailedTransfer, setNearshopzFailedTransfer] = useState(false);
  const [externalFailedTransfer, setExternalFailedTransfer] = useState(false);
  const [reimbursementFailedTransfer, setReimbursementFailedTransfer] =
    useState(false);
  const [nearshopzDifference, setNearshopzDifference] = useState();
  const [reimbursementDifference, setReimbursementDifference] = useState(
    (0).toFixed(2)
  );
  const [externalDifference, setExternalDifference] = useState((0).toFixed(2));
  const [enableButton, setEnableButton] = useState(false);
  const handleCheckboxChange = (event) => {
    setChecked(event.target.checked);
    setEnableButton((prevEnableButton) => !prevEnableButton);
  };

  const location = useLocation();

  const [orderList, setOrderList] = useState([]);

  useEffect(() => {
    const orderid = localStorage.getItem("PendingOrderId");
    props.getOrderDetails(orderid);
  }, []);

  useEffect(() => {
    const details = props?.OrderDetails?.orderdetails;
    setOrderList(details);
    if (orderList?.id) {
      props.checkSettlementStatus({ orderId: orderList?.id });
    }
  }, [props?.OrderDetails?.orderdetails]);

  const handleClose = () => {
    props.history.push({
      pathname: `/home/Payment`,
    });
  };

  useEffect(() => {
    if (orderList) {
      if (orderList?.deliveryBy === 1 && orderList?.paymentStatus != 1) {
        setSellerTransfer(true);
      }
      if (orderList?.deliveryBy === 2) {
        setNearshopzTransfer(true);
        setSellerTransfer(true);
      }
      if (orderList?.deliveryBy === 3) {
        setExternalTransfer(true);
        setSellerTransfer(true);
      }
      if (orderList?.deliveryBy === 1 && orderList?.paymentStatus === 1) {
        setSellerReimbursement(true);
      }
    }
  }, [orderList]);
  useEffect(() => {
    console.log("Updated sellerReimbursement:", sellerReimbursement);
  }, [sellerReimbursement]);

  const handleEnableEditClick = () => {
    setEnableEdit(true);
    if (orderList?.deliveryBy === 1 && orderList?.paymentStatus != 1) {
      setSellerEdit(true);
    }
    if (orderList?.deliveryBy === 2) {
      setNearshopzEdit(true);
      setSellerEdit(true);
    }
    if (orderList?.deliveryBy === 3) {
      setExternalEdit(true);
      setSellerEdit(true);
    }
    if (orderList?.deliveryBy === 1) {
      if (orderList?.paymentStatus === 1) {
        setReimbursementEdit(true);
      }
    }
  };

  useEffect(() => {
    const differenceSeller = (
      orderList?.editedAmountForSeller != null
        ? orderList?.amountForSeller - orderList?.editedAmountForSeller
        : orderList?.amountForSeller - orderList?.amountForSeller
    ).toFixed(2);
    setDifference(differenceSeller);
    const differenceNearshopz = (
      orderList?.editedAmountForNearshopzDeliveryPerson != null
        ? orderList?.amountForNearshopzDeliveryPerson -
          orderList?.editedAmountForNearshopzDeliveryPerson
        : orderList?.amountForNearshopzDeliveryPerson -
          orderList?.amountForNearshopzDeliveryPerson
    ).toFixed(2);
    setNearshopzDifference(differenceNearshopz);
    const differenceExternal = (
      orderList?.editedAmountForExternalDeliveryPerson != null
        ? orderList?.amountForExternalDeliveryPerson -
          orderList?.editedAmountForExternalDeliveryPerson
        : orderList?.amountForExternalDeliveryPerson -
          orderList?.amountForExternalDeliveryPerson
    ).toFixed(2);
    setExternalDifference(differenceExternal);
  }, [orderList]);

  useEffect(() => {
    const sellerPrice = (
      orderList?.editedAmountForSeller ?? orderList?.amountForSeller
    )?.toFixed(2);
    setRevisedSellerPrice(sellerPrice);
    const nearshopzSellerFee = (
      orderList?.editedAmountForNearshopzDeliveryPerson ??
      orderList?.amountForNearshopzDeliveryPerson
    )?.toFixed(2);
    setRevisedNearshopz(nearshopzSellerFee);
    const externalFee = (
      orderList?.editedAmountForExternalDeliveryPerson ??
      orderList?.amountForExternalDeliveryPerson
    )?.toFixed(2);
    setRevisedExternal(externalFee);
    const reimbursement = (
      orderList?.editedSellerReimbursement ?? orderList?.sellerReimbursement
    )?.toFixed(2);
    setRevisedsellerReimbursement(reimbursement);
    if (
      props?.OrderDetails &&
      props?.OrderDetails?.orderdetails?.transfers?.length
    ) {
      const sellertransfer = props?.OrderDetails?.orderdetails?.transfers.find(
        (transfer) => transfer?.transferTo === "seller"
      );
      setTransfered(
        sellertransfer?.settlement_status ?? sellertransfer?.transfer_status
      );
      console.log("Seller Transfer:", sellertransfer);
      const nearshopzTransfer =
        props?.OrderDetails?.orderdetails?.transfers.find(
          (transfer) => transfer?.transferTo === "nearshopzDeliveryPerson"
        );
      console.log("nTransfer:", nearshopzTransfer);
      if (nearshopzTransfer) {
        setNearshopzTransfered(
          nearshopzTransfer?.settlement_status ??
            nearshopzTransfer?.transfer_status
        );
      }
      const ExternalTransfer =
        props?.OrderDetails?.orderdetails?.transfers.find(
          (transfer) => transfer?.transferTo === "externalDeliveryPerson"
        );
      console.log("Transfer:", ExternalTransfer);
      if (ExternalTransfer) {
        setExternalTransfered(
          ExternalTransfer?.settlement_status ??
            ExternalTransfer?.transfer_status
        );
      }
    }
    // if (nearshopzTransfer) {
    //   setNearshopzTransfered(
    //     props?.OrderDetails?.orderdetails?.transfers?.slice(-1)[0]
    //       ?.settlement_status ??
    //       props?.OrderDetails?.orderdetails?.transfers?.slice(-1)[0]
    //         ?.transfer_status
    //   );
    // }
    // console.log("ki", transfered)
  }, [orderList]);

  const handleNearshopzChange = (e) => {
    const value = e.target.value.trim() === "" ? 0 : parseFloat(e.target.value);
    setRevisedNearshopz(value);

    const newDifference = orderList?.amountForNearshopzDeliveryPerson - value;

    setNearshopzDifference(newDifference.toFixed(2));
    setRevisedNearshopzEdit(true);
  };

  const handleReimbursementChange = (e) => {
    const value = parseFloat(e.target.value) || 0;
    setRevisedsellerReimbursement(value);

    const newDifference = orderList?.sellerReimbursement - value;

    setReimbursementDifference(newDifference.toFixed(2));
    setRevisedsellerReimbursementEdit(true);
  };

  const handleExternalChange = (e) => {
    const value = e.target.value.trim() === "" ? 0 : parseFloat(e.target.value);
    setRevisedExternal(value);

    const newDifference = orderList?.amountForExternalDeliveryPerson - value;

    setExternalDifference(newDifference.toFixed(2));
    setRevisedExternalEdit(true);
  };

  const handleCommentClick = () => {
    props.updateSettlementDetails({
      orderid: orderList.id,
      settlementComment: comment,
    });
  };

  const [enableSave, setEnableSave] = useState(false);

  const handleEnableSaveClick = () => {
    setEnableSave(true);
    if (revisedSellerPriceEdit) {
      props.updateSettlementDetails({
        orderid: orderList.id,
        editedAmountForSeller: parseFloat(revisedSellerPrice),
      });
    }
    if (revisedNearshopzEdit) {
      props.updateSettlementDetails({
        orderid: orderList.id,
        editedAmountForNearshopz: parseFloat(revisedNearshopz),
      });
    }
    if (revisedExternalEdit) {
      props.updateSettlementDetails({
        orderid: orderList.id,
        editedAmountForExternalDeliveryPerson: parseFloat(revisedExternal),
      });
    }
    if (revisedsellerReimbursementEdit) {
      props.updateSettlementDetails({
        orderid: orderList.id,
        editedAmountForExternalDeliveryPerson: parseFloat(
          revisedsellerReimbursement
        ),
      });
    }
  };

  const handleTransferClick = () => {
    setTransfer(true);
  };

  useEffect(() => {
    if (
      props.CreateTransferError &&
      (sellerFailedTransfer ||
        externalFailedTransfer ||
        nearshopzFailedTransfer)
    ) {
      alert(props?.CreateTransferError);
    }
  }, [props?.CreateTransferError,sellerFailedTransfer,externalFailedTransfer,nearshopzFailedTransfer]);

  useEffect(() => {
    if (
      props?.CreateTransferData &&
      props?.CreateTransferData?.createTransfer?.transfers?.length
    ) {
      const sellertransfer =
        props?.CreateTransferData?.createTransfer?.transfers.find(
          (transfer) => transfer?.transferTo === "seller"
        );
      console.log("sekller", sellertransfer);
      if (sellertransfer && sellerFailedTransfer) {
        console.log("working m");
        setTransfered(
          sellertransfer?.settlement_status ?? sellertransfer?.transfer_status
        );
        console.log("lutt", transfered);
      }

      const nearshopzTransfer =
        props?.CreateTransferData?.createTransfer?.transfers.find(
          (transfer) => transfer?.transferTo === "nearshopzDeliveryPerson"
        );

      if (nearshopzTransfer && nearshopzFailedTransfer) {
        setNearshopzTransfered(
          nearshopzTransfer?.settlement_status ??
            nearshopzTransfer?.transfer_status
        );
      }
      const ExternalTransfer =
        props?.CreateTransferData?.createTransfer?.transfers.find(
          (transfer) => transfer?.transferTo === "externalDeliveryPerson"
        );

      if (ExternalTransfer && externalFailedTransfer) {
        setExternalTransfered(
          ExternalTransfer?.settlement_status ??
            ExternalTransfer?.transfer_status
        );
      }
    }
  }, [props?.CreateTransferData]);

  const handleSellerTransfer = () => {
    setTransferTo("seller");
    props?.createTransfer({
      orderId: orderList.id,
      transferTo: "seller",
    });
    setSellerFailedTransfer(true);
    if (props?.CreateTransferDataError) {
      setFailed(true);
    }
  };

  const handleNearshopzTransfer = () => {
    setTransferTo("nearshopzDeliveryPerson");
    props.createTransfer({
      orderId: orderList.id,
      transferTo: "nearshopzDeliveryPerson",
      // razorPayAccountId: orderList.razorpayPaymentId,
    });
    setNearshopzFailedTransfer(true);
    if (props.CreateTransferData) {
      setNearshopzTransfered(true);
      console.log("true aayi", nearshopzTransfered);
    }
    if (props.CreateTransferFailed === true) {
      setNearshopzFailed(true);
      console.log("bggg", failed);
    }
    // if (props.CreateTransferError) {
    //   alert(props.CreateTransferError);
    // }
  };

  const handleExternalTransfer = () => {
    setTransferTo("externalDeliveryPerson");
    props.createTransfer({
      orderId: orderList.id,
      transferTo: "externalDeliveryPerson",
      razorPayAccountId: selectedAccountId,
    });
    setExternalFailedTransfer(true);
    if (props.CreateTransferData) {
      setExternalTransfered(true);
    }
    if (props.CreateTransferFailed === true) {
      setExternalFailed(true);
    }
    // if (props.CreateTransferError) {
    //   alert(props.CreateTransferError);
    // }
  };

  const handletransferChange = (e) => {
    const value = e.target.value.trim() === "" ? 0 : parseFloat(e.target.value);

    console.log("Input value:", value);

    const amountForSeller = parseFloat(orderList?.amountForSeller ?? 0);
    const editedAmountForSeller = parseFloat(
      props?.UpdateSettelementData?.updateSettlementDetails
        ?.editedAmountForSeller ?? 0
    );

    console.log("amountForSeller:", amountForSeller);
    console.log("editedAmountForSeller:", editedAmountForSeller);

    const newDifference = amountForSeller - value;

    console.log("Calculated newDifference:", newDifference);

    setRevisedSellerPrice(value);
    setDifference(parseFloat(newDifference.toFixed(2)));
    console.log("Updated difference state:", newDifference.toFixed(2));
    setRevisedSellerPriceEdit(true);
  };
  const handleInProgressClick = () => {
    props.checkSettlementStatus({
      orderId: orderList?.id,
      forceCompleteSettlement: true,
    });
  };

  const handleCommentChange = (event) => {
    setComment(event.target.value);
  };

  console.log("new dif", transfered);

  return (
    <div
      style={{
        backgroundColor: "white",
        transform: "translateY(100px)",
        width: "100%",
        borderRadius: "30px",
        height: "auto",
        padding: "15px",
        boxShadow:
          "0px 11px 15px -7px rgb(0 0 0 / 20%), 0px 24px 38px 3px rgb(0 0 0 / 14%), 0px 9px 46px 8px rgb(0 0 0 / 12%)",
      }}
      position="sticky"
    >
      <div style={{ display: "flex" }}>
        <Typography
          style={{ cursor: "pointer" }}
          onClick={handleClose}
          className="header-font"
          fontSize={HeaderNavigationFontSize_20}
        >
          Not Processed
        </Typography>
        <Typography
          className="header-font"
          fontSize={HeaderNavigationFontSize_20}
        >
          /
        </Typography>
        <Typography
          className="header-font"
          fontSize={HeaderNavigationFontSize_20}
          style={{ color: "#85BE49" }}
        >
          View
        </Typography>
      </div>
      <div style={{ paddingTop: "20px", boxSizing: "border-box" }}>
        {/* {orderList?.map((order, index) => ( */}
        <Grid sx={{ paddingLeft: "0px", paddingTop: "20px" }}>
          <CustomCard
            padding={"30px"}
            width="100%"
            height="120px"
            background="#F4F5F9"
            sx={{ display: "flex", alignItems: "center" }}
            boxShadow="none"
          >
            <Box>
              <Grid
                container
                spacing={2}
                sx={{ paddingLeft: "0px", paddingTop: "0px" }}
              >
                <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "10px",
                      alignItems: "flex-start",
                      justifyContent: "flex-start",
                    }}
                  >
                    <Typography
                      className="Card-text"
                      fontSize={contentFontSize_20}
                      color={"#2D93FB"}
                      style={{
                        cursor: "pointer",
                        textDecoration: "underline",
                      }}
                      sx={{ fontFamily: "Roboto", fontWeight: "600" }}
                      // onClick={() => handleOrder(order.id)}
                    >
                      #{orderList?.orderNumber}
                    </Typography>
                    <Typography
                      fontSize={contentFontsize_14}
                      // className="Card-subtext"
                    >
                      {orderList?.transferSettlement?.length > 0 && (
                        <>
                          {orderList.transferSettlement[0].created}{" "}
                          {orderList.transferSettlement[0].createdTime}
                        </>
                      )}
                    </Typography>
                  </div>
                </Grid>

                <Grid item xs={12} sm={12} md={4} lg={3} xl={3}>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "10px",
                      alignItems: "flex-start",
                      justifyContent: "flex-start",
                      paddingTop: "10px",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        gap: "10px",
                      }}
                    >
                      <img src={Amount} alt="Amount" />
                      <Typography
                        fontSize={contentFontsize_14}
                        sx={{
                          fontWeight: "600",
                          fontFamily: "Roboto",
                          color: "#13801C",
                        }}
                      >
                        Amount
                      </Typography>
                    </div>

                    {/* Centering the Amount */}
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        width: "36%",
                      }}
                    >
                      <Typography
                        fontSize={contentFontsize_14}
                        sx={{ fontWeight: "600", fontFamily: "Roboto" }}
                      >
                        ₹{" "}
                        {orderList?.totalPayable
                          ? orderList.totalPayable.toFixed(2)
                          : "0.00"}
                      </Typography>
                    </div>
                  </div>
                </Grid>

                {/* Adjusted status Grid item */}
                <Grid item xs={12} sm={12} md={4} lg={3} xl={3}>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "10px",
                      alignItems: "flex-start",
                      justifyContent: "flex-start",
                      paddingTop: "10px",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        gap: "10px",
                      }}
                    >
                      <img src={deliver} alt="Amount" />
                      <Typography
                        fontSize={contentFontsize_14}
                        sx={{
                          fontWeight: "600",
                          fontFamily: "Roboto",
                          color: "#13801C",
                        }}
                      >
                        Delivered By
                      </Typography>
                    </div>

                    {/* Centering the Amount */}
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        width: "50%",
                      }}
                    >
                      <Typography
                        fontSize={contentFontsize_14}
                        sx={{ fontWeight: "600", fontFamily: "Roboto" }}
                      >
                        {orderList?.deliveryBy === 1
                          ? "Seller"
                          : orderList?.deliveryBy === 2
                          ? "Nearshopz"
                          : "External Delivery Person"}
                      </Typography>
                    </div>
                  </div>
                </Grid>

                <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "10px",
                      alignItems: "center",
                      justifyContent: "center",
                      paddingTop: "10px",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        gap: "10px",
                      }}
                    >
                      <img src={payment} alt="Amount" />

                      <Typography
                        fontSize={contentFontsize_14}
                        sx={{
                          fontWeight: "600",
                          fontFamily: "Roboto",
                          color: "#13801C",
                        }}
                      >
                        Payment Method
                      </Typography>
                    </div>

                    {/* Centering the Amount */}
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        // width: "50%",
                      }}
                    >
                      <Typography
                        fontSize={contentFontsize_14}
                        sx={{ fontWeight: "600", fontFamily: "Roboto" }}
                      >
                        {orderList?.paymentStatus === 1
                          ? "COD"
                          : orderList?.paymentStatus === 2
                          ? "Online Payment"
                          : "COD/Online Payment"}
                      </Typography>
                    </div>
                  </div>
                </Grid>
              </Grid>
            </Box>
          </CustomCard>
        </Grid>
        {/* ))} */}
      </div>
      <div style={{ padding: "15px" }}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginTop: "20px",
          }}
        >
          <div>
            <Typography
              fontSize={contentFontSize_20}
              sx={{
                fontFamily: "Roboto",
                fontWeight: "600",
                color: "black",
                textAlign: "left",
              }}
            >
              ORDER PAYMENT BREAKDOWN 
            </Typography>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              gap: "10px",
            }}
          >
            <img
              src={enableEdit ? diableEdit : EditIcon}
              onClick={handleEnableEditClick}
              style={{ cursor: "pointer" }}
            />
            <img
              src={enableSave ? save : EditSve}
              onClick={handleEnableSaveClick}
            />
          </div>
        </div>
        <div>
          {/* Header Row */}
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              padding: "30px 0",
            }}
          >
            <div style={{ flex: 1 }}>
              <Typography style={{ fontWeight: 600 }}></Typography>
            </div>
            <div style={{ flex: 2 }}>
              <Typography style={{ fontWeight: 600 }}>
                Original Price
              </Typography>
            </div>
            <div style={{ flex: 2 }}>
              <Typography style={{ fontWeight: 600, color: "#D40000" }}>
                Revised Price
              </Typography>
            </div>
            <div style={{ flex: 2 }}>
              <Typography style={{ fontWeight: 600 }}>Difference</Typography>
            </div>
            <div style={{ flex: 1 }}>
              <Typography style={{ fontWeight: 600, textAlign: "center" }}>
                Action
              </Typography>
            </div>
          </div>

          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              padding: "10px 0 30px 0",
              borderBottom: "1px solid #e0e0e0",
            }}
          >
            <div style={{ flex: 1 }}>
              <Typography
                style={{ fontWeight: 500, textAlign: "left", color: "black" }}
              >
                Seller Fee
              </Typography>
            </div>

            <div style={{ flex: 2 }}>
              <Typography style={{ fontWeight: 500 }}>
                ₹ {(orderList?.amountForSeller ?? 0).toFixed(2)}
              </Typography>
            </div>

            <div style={{ flex: 2, width: "100%" }}>
              <TextField
                style={{ width: "50%" }}
                variant="outlined"
                value={
                  sellerEdit
                    ? revisedSellerPrice
                    : Number(revisedSellerPrice).toFixed(2)
                }
                onChange={handletransferChange}
                size="small"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">₹</InputAdornment>
                  ),
                  style: {
                    color: sellerEdit ? "#D40000" : "#979797",
                    fontWeight: "bold",
                  },
                }}
                disabled={!sellerEdit}
              />
            </div>

            <div style={{ flex: 2 }}>
              <Typography style={{ fontWeight: 500 }}>
                {" "}
                ₹{difference}
              </Typography>
            </div>

            <div style={{ flex: 1, display: "flex", justifyContent: "center" }}>
              {transfered == null && (
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <CustomButton
                    variant="contained"
                    style={{
                      background: sellerTransfer ? "#72B01D" : "#D9D9D9",
                      color: "white",
                    }}
                    background={sellerTransfer ? "#72B01D" : "#D9D9D9"}
                    width="120px"
                    disabled={!sellerTransfer}
                    onClick={handleSellerTransfer}
                  >
                    Transfer
                  </CustomButton>
                  {failed && (
                    <div>
                      <Typography style={{ color: "red" }}>
                        Failed Try Again
                      </Typography>
                    </div>
                  )}
                </div>
              )}
              {transfered && (
                <CustomButton
                  width="140px"
                  //  height="43px"
                  borderRadius="30px"
                  color="#17A64F"
                  background="white"
                  variant="outlined"
                  border="5px solid #17A64F"
                  borderColor="#17A64F"
                  hasIcon={true}
                  iconPosition="startIcon"
                >
                  <img
                    src={Direction}
                    style={{
                      // width: "15px",
                      // height: "15px",
                      marginRight: "10px",
                    }}
                  />
                  {transfered}
                </CustomButton>
              )}
            </div>
          </div>

          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              padding: "40px 0 30px 0",
              borderBottom: "1px solid #e0e0e0",
            }}
          >
            <div style={{ flex: 1 }}>
              <Typography
                style={{ fontWeight: 500, textAlign: "left", color: "black" }}
              >
                NearShopz Delivery <br />
                Partner's Fee
              </Typography>
            </div>

            <div style={{ flex: 2 }}>
              <Typography style={{ fontWeight: 500 }}>
                ₹{" "}
                {orderList?.amountForNearshopzDeliveryPerson
                  ? orderList.amountForNearshopzDeliveryPerson.toFixed(2)
                  : "0.00"}{" "}
              </Typography>
            </div>

            <div style={{ flex: 2, width: "100%" }}>
              <TextField
                style={{ width: "50%" }}
                variant="outlined"
                value={
                  nearshopzEdit
                    ? revisedNearshopz
                    : Number(revisedNearshopz).toFixed(2)
                }
                onChange={handleNearshopzChange}
                size="small"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">₹</InputAdornment>
                  ),
                  style: {
                    color: nearshopzEdit ? "#D40000" : "#979797",
                    fontWeight: "bold",
                  },
                }}
                disabled={!nearshopzEdit}
              />
            </div>

            <div style={{ flex: 2 }}>
              <Typography style={{ fontWeight: 500 }}>
                ₹{nearshopzDifference}
              </Typography>
            </div>

            <div style={{ flex: 1, display: "flex", justifyContent: "center" }}>
              {!nearshopzTransfered && (
                <div>
                  <CustomButton
                    variant="contained"
                    style={{
                      background: nearshopzTransfer ? "#72B01D" : "#D9D9D9",
                      color: "white",
                    }}
                    background={nearshopzTransfer ? "#72B01D" : "#D9D9D9"}
                    width="120px"
                    disabled={!nearshopzTransfer}
                    onClick={handleNearshopzTransfer}
                  >
                    Transfer
                  </CustomButton>
                  {nearshopzFailed && (
                    <div>
                      <Typography style={{ color: "red" }}>
                        Failed Try Again
                      </Typography>
                    </div>
                  )}
                </div>
              )}
              {nearshopzTransfered && (
                <CustomButton
                  width="140px"
                  //  height="43px"
                  borderRadius="30px"
                  color="#17A64F"
                  background="white"
                  variant="outlined"
                  border="5px solid #17A64F"
                  borderColor="#17A64F"
                  hasIcon={true}
                  iconPosition="startIcon"
                >
                  <img
                    src={Direction}
                    style={{
                      // width: "15px",
                      // height: "15px",
                      marginRight: "10px",
                    }}
                  />
                  Transfered
                </CustomButton>
              )}
            </div>
          </div>

          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              padding: "40px 0 30px 0",
              borderBottom: "1px solid #e0e0e0",
            }}
          >
            <div style={{ flex: 1 }}>
              <Typography
                style={{ fontWeight: 500, textAlign: "left", color: "black" }}
              >
                External Delivery Partner's Fee
              </Typography>
              {selectedTransfer && (
                <div style={{ display: "flex", justifyContent: "flex-start" }}>
                  <Typography style={{ color: "rgba(212, 0, 0, 1)" }}>
                    **{selectedTransfer}
                  </Typography>
                </div>
              )}
            </div>

            <div style={{ flex: 2 }}>
              <Typography style={{ fontWeight: 500 }}>
                ₹{" "}
                {orderList?.amountForExternalDeliveryPerson
                  ? orderList.amountForExternalDeliveryPerson.toFixed(2)
                  : "0.00"}
              </Typography>
            </div>

            <div style={{ flex: 2, width: "100%" }}>
              <TextField
                style={{ width: "50%" }}
                variant="outlined"
                value={revisedExternal}
                onChange={handleExternalChange}
                size="small"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">₹</InputAdornment>
                  ),
                  style: {
                    color: externalEdit ? "#D40000" : "#979797",
                    fontWeight: "bold",
                  },
                }}
                disabled={!externalEdit}
              />
            </div>

            <div style={{ flex: 2 }}>
              <Typography style={{ fontWeight: 500 }}>
                ₹{externalDifference}
              </Typography>
            </div>

            <div style={{ flex: 1, display: "flex", justifyContent: "center" }}>
              {externalTransfered == null && (
                <CustomButton
                  variant="contained"
                  style={{
                    background: externalTransfer ? "#72B01D" : "#D9D9D9",
                    color: "white",
                  }}
                  background={externalTransfer ? "#72B01D" : "#D9D9D9"}
                  width="120px"
                  onClick={
                    firstClick ? handleExternalTransfer : handleTransferClick
                  }
                  disabled={!externalTransfer}
                >
                  Transfer
                </CustomButton>
              )}
              {externalTransfered && (
                <CustomButton
                  width="140px"
                  //  height="43px"
                  borderRadius="30px"
                  color="#17A64F"
                  background="white"
                  variant="outlined"
                  border="5px solid #17A64F"
                  borderColor="#17A64F"
                  hasIcon={true}
                  iconPosition="startIcon"
                >
                  <img
                    src={Direction}
                    style={{
                      // width: "15px",
                      // height: "15px",
                      marginRight: "10px",
                    }}
                  />
                  {externalTransfered}
                </CustomButton>
              )}
            </div>
          </div>

          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              padding: "40px 0 30px 0",
              borderBottom: "1px solid #e0e0e0",
            }}
          >
            <div style={{ flex: 1 }}>
              <Typography
                style={{ fontWeight: 500, textAlign: "left", color: "black" }}
              >
                Refund for Customer
              </Typography>
            </div>

            <div style={{ flex: 2 }}>
              <Typography style={{ fontWeight: 500 }}>
                ₹{" "}
                {orderList?.amountForCustomer
                  ? orderList.amountForCustomer.toFixed(2)
                  : "0.00"}
              </Typography>
            </div>

            <div style={{ flex: 2, width: "100%" }}>
              <TextField
                disabled
                style={{ width: "50%" }}
                variant="outlined"
                value={
                  orderList?.amountForCustomer
                    ? orderList.amountForCustomer.toFixed(2)
                    : "0.00"
                }
                size="small"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">₹</InputAdornment>
                  ),
                  style: { color: "#979797", fontWeight: "bold" },
                }}
              />
            </div>

            <div style={{ flex: 2 }}>
              <Typography style={{ fontWeight: 500 }}>
                ₹{" "}
                {(
                  orderList?.amountForCustomer - orderList?.amountForCustomer
                ).toFixed(2)}
              </Typography>
            </div>

            <div style={{ flex: 1, display: "flex", justifyContent: "center" }}>
              {/* <CustomButton
                variant="contained"
                style={{ backgroundColor: "#4CAF50", color: "white" }}
                width="120px"
              >
                Transfer
              </CustomButton> */}
            </div>
          </div>

          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              padding: "40px 0 30px 0",
              borderBottom: "1px solid #e0e0e0",
            }}
          >
            <div style={{ flex: 1 }}>
              <Typography
                style={{ fontWeight: 500, textAlign: "left", color: "black" }}
              >
                Razor Pay Fee
              </Typography>
            </div>

            <div style={{ flex: 2 }}>
              <Typography style={{ fontWeight: 500 }}>
                ₹ {(orderList?.razorPayFeesAndTax ?? 0).toFixed(2)}
              </Typography>
            </div>

            <div style={{ flex: 2, width: "100%" }}>
              <TextField
                style={{ width: "50%" }}
                variant="outlined"
                disabled
                value={
                  orderList?.editedRazorPayFeesAndTax === 0 ||
                  orderList?.editedRazorPayFeesAndTax == null
                    ? (orderList?.razorPayFeesAndTax ?? 0).toFixed(2)
                    : (orderList?.editedRazorPayFeesAndTax).toFixed(2)
                }
                size="small"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">₹</InputAdornment>
                  ),
                  style: { color: "#979797", fontWeight: "bold" },
                }}
              />
            </div>

            <div style={{ flex: 2 }}>
              <Typography style={{ fontWeight: 500 }}>
                ₹{" "}
                {(orderList?.editedRazorPayFeesAndTax != null
                  ? orderList?.razorPayFeesAndTax -
                    orderList?.editedRazorPayFeesAndTax
                  : orderList?.razorPayFeesAndTax -
                    orderList?.razorPayFeesAndTax
                ).toFixed(2)}
              </Typography>
            </div>

            <div style={{ flex: 1, display: "flex", justifyContent: "center" }}>
              {/* <CustomButton
                variant="contained"
                style={{ backgroundColor: "#4CAF50", color: "white" }}
                width="120px"
                
              >
                Transfer
              </CustomButton> */}
            </div>
          </div>

          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              padding: "40px 0 30px 0",
              borderBottom: "1px solid #e0e0e0",
            }}
          >
            <div style={{ flex: 1 }}>
              <Typography
                style={{ fontWeight: 500, textAlign: "left", color: "black" }}
              >
                NearShopz
              </Typography>
            </div>

            <div style={{ flex: 2 }}>
              <Typography style={{ fontWeight: 500 }}>
                ₹
                {orderList?.amountForNearshopz
                  ? orderList.amountForNearshopz.toFixed(2)
                  : "0.00"}
              </Typography>
            </div>

            <div style={{ flex: 2, width: "100%" }}>
              <TextField
                disabled
                style={{ width: "50%" }}
                variant="outlined"
                value={
                  orderList?.editedAmountForNearshopz === 0 ||
                  orderList?.editedAmountForNearshopz == null
                    ? orderList?.amountForNearshopz
                      ? orderList.amountForNearshopz.toFixed(2)
                      : "0.00"
                    : orderList?.editedAmountForNearshopz
                    ? orderList.editedAmountForNearshopz.toFixed(2)
                    : "0.00"
                }
                size="small"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">₹</InputAdornment>
                  ),
                  style: { color: "#979797", fontWeight: "bold" },
                }}
              />
            </div>

            <div style={{ flex: 2 }}>
              <Typography style={{ fontWeight: 500 }}>
                ₹{" "}
                {(orderList?.editedAmountForNearshopz != null
                  ? orderList?.amountForNearshopz -
                    orderList?.editedAmountForNearshopz
                  : orderList?.amountForNearshopz -
                    orderList?.amountForNearshopz
                ).toFixed(2)}
              </Typography>
            </div>

            <div style={{ flex: 1, display: "flex", justifyContent: "center" }}>
              {/* <CustomButton
                variant="contained"
                style={{ backgroundColor: "#4CAF50", color: "white" }}
                width="120px"
                
              >
                Transfer
              </CustomButton> */}
            </div>
          </div>

          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              padding: "40px 0 30px 0",
              borderBottom: "1px solid #e0e0e0",
            }}
          >
            <div style={{ flex: 1 }}>
              <Typography
                style={{ fontWeight: 500, textAlign: "left", color: "black" }}
              >
                Seller Reimbursement
              </Typography>
            </div>

            <div style={{ flex: 2 }}>
              <Typography style={{ fontWeight: 500 }}>
                ₹ {(orderList?.sellerReimbursement ?? 0).toFixed(2)}
              </Typography>
            </div>

            <div style={{ flex: 2, width: "100%" }}>
              <TextField
                style={{ width: "50%" }}
                variant="outlined"
                value={revisedsellerReimbursement}
                onChange={handleReimbursementChange}
                size="small"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">₹</InputAdornment>
                  ),
                  style: {
                    color: reimbursementEdit ? "#D40000" : "#979797",
                    fontWeight: "bold",
                  },
                }}
                disabled={!reimbursementEdit}
              />
            </div>

            <div style={{ flex: 2 }}>
              <Typography style={{ fontWeight: 500 }}>
                ₹ {reimbursementDifference}
              </Typography>
            </div>

            <div style={{ flex: 1, display: "flex", justifyContent: "center" }}>
              {!reimbursementTransfered && (
                <CustomButton
                  variant="contained"
                  style={{
                    background: sellerReimbursement ? "#72B01D" : "#D9D9D9",
                    color: "white",
                  }}
                  background={
                    sellerReimbursement && orderList?.sellerReimbursement > 0
                      ? "#72B01D"
                      : "#D9D9D9"
                  }
                  width="120px"
                  disabled={!sellerReimbursement}
                  onClick={handleSellerTransfer}
                >
                  Transfer
                </CustomButton>
              )}
              {reimbursementTransfered && (
                <CustomButton
                  width="140px"
                  //  height="43px"
                  borderRadius="30px"
                  color="#17A64F"
                  background="white"
                  variant="outlined"
                  border="5px solid #17A64F"
                  borderColor="#17A64F"
                  hasIcon={true}
                  iconPosition="startIcon"
                >
                  <img
                    src={Direction}
                    style={{
                      // width: "15px",
                      // height: "15px",
                      marginRight: "10px",
                    }}
                  />
                  Transfered
                </CustomButton>
              )}
            </div>
          </div>

          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              padding: "40px 0 30px 0",
              // borderBottom: "1px solid #e0e0e0",
            }}
          >
            <div style={{ flex: 1 }}>
              <Typography
                style={{ fontWeight: 500, textAlign: "left", color: "#D40000" }}
              >
                Extra Amount By Nearshopz
              </Typography>
            </div>

            <div style={{ flex: 2 }}>
              <Typography style={{ fontWeight: 500 }}>
                ₹
                {orderList?.extraAmountByNearshopz
                  ? orderList.extraAmountByNearshopz.toFixed(2)
                  : "0.00"}
              </Typography>
            </div>

            <div style={{ flex: 2, width: "100%" }}>
              <TextField
                style={{ width: "50%" }}
                disabled
                variant="outlined"
                value={
                  orderList?.editedExtraAmountByNearshopz === 0 ||
                  orderList?.editedExtraAmountByNearshopz == null
                    ? (orderList?.extraAmountByNearshopz ?? 0).toFixed(2)
                    : (orderList?.editedExtraAmountByNearshopz).toFixed(2)
                }
                size="small"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">₹</InputAdornment>
                  ),
                  style: { color: "#979797", fontWeight: "bold" },
                }}
              />
            </div>

            <div style={{ flex: 2 }}>
              <Typography style={{ fontWeight: 500 }}>
                ₹{" "}
                {(orderList?.editedExtraAmountByNearshopz != null
                  ? orderList?.extraAmountByNearshopz -
                    orderList?.editedExtraAmountByNearshopz
                  : orderList?.extraAmountByNearshopz -
                    orderList?.extraAmountByNearshopz
                ).toFixed(2)}
              </Typography>
            </div>

            <div style={{ flex: 1, display: "flex", justifyContent: "center" }}>
              {/* <CustomButton
                variant="contained"
                style={{ backgroundColor: "#4CAF50", color: "white" }}
                width="120px"
              >
                Transfer
              </CustomButton> */}
            </div>
          </div>
          <div style={{ paddingTop: "30px" }}>
            <Typography
              style={{
                textAlign: "left",
                color: "black",
                marginBottom: "10px",
                fontWeight: "600",
              }}
              fontSize={contentFontSize_18}
            >
              Comments
            </Typography>
            <TextField
              name="message"
              multiline={true}
              rows="3"
              value={comment}
              onChange={handleCommentChange}
              sx={{
                width: "100%",
                "& .MuiOutlinedInput-root": {
                  borderRadius: "10px",
                  "& fieldset": {
                    borderColor: "#BDBABA",
                  },
                  "&.Mui-focused fieldset": {
                    borderColor: "#BDBABA",
                  },
                },
                "& .MuiInputBase-input": {
                  // borderRadius: "20px",
                },
              }}
            ></TextField>
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                marginTop: "10px",
              }}
            >
              <CustomButton
                variant="contained"
                style={{ backgroundColor: "#4CAF50", color: "white" }}
                borderRadius="10px"
                width="160px"
                onClick={handleCommentClick}
              >
                <Typography
                  fontSize={contentFontSize_16}
                  style={{ textTransform: "none" }}
                >
                  Save Comments
                </Typography>
              </CustomButton>
            </div>
            <div style={{ marginTop: "20px" }}>
              <FormControlLabel
                control={
                  <Checkbox
                    icon={
                      <Box
                        sx={{
                          width: 27,
                          height: 27,
                          border: "1px solid black",
                          borderRadius: "4px",
                        }}
                      />
                    }
                    checkedIcon={
                      <Box
                        sx={{
                          width: 27,
                          height: 27,
                          border: "1px solid #D9D9D9",
                          borderRadius: "4px",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          backgroundColor: "#D9D9D9",
                        }}
                      >
                        <Box
                          sx={{
                            width: 18,
                            height: 9,
                            borderLeft: "2px solid white",
                            borderBottom: "2px solid white",
                            transform: "rotate(-45deg)",
                          }}
                        />
                      </Box>
                    }
                    checked={checked}
                    onChange={handleCheckboxChange}
                    sx={{
                      color: "white",
                      "& .MuiSvgIcon-root": {
                        borderRadius: "4px",
                      },
                    }}
                  />
                }
                label={
                  <Typography
                    variant="body1"
                    style={{
                      fontWeight: "600",
                      color: "black",
                      marginLeft: "10px",
                    }}
                    fontSize={contentFontSize_18}
                  >
                    Admin requests order closure as all payments are settled.
                  </Typography>
                }
              />
            </div>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              padding: "40px 0 30px 0",
              // borderBottom: "1px solid #e0e0e0",
            }}
          >
            <CustomButton
              variant="contained"
              style={{ color: "white" }}
              background={enableButton ? "#659B1B" : "#D9D9D9"}
              // color={enableButton  ? "white" : "#659B1B"}
              width="30%"
              borderRadius="15px"
              disabled={!enableButton}
              onClick={handleInProgressClick}
            >
              <Typography
                fontSize={ButtonFontSize_18}
                style={{ textTransform: "none", fontWeight: "500" }}
              >
                In progress
              </Typography>
            </CustomButton>
          </div>
        </div>
      </div>
      {transfer && (
        <TransferPopup
          transfer={transfer}
          setTransfer={setTransfer}
          firstExternal={firstExternal}
          setFirstExternal={setFirstExternal}
          selectedTransfer={selectedTransfer}
          setSelectedTransfer={setSelectedTransfer}
          firstClick={firstClick}
          setFirstClick={setFirstClick}
          setSelectedAccountId={setSelectedAccountId}
          selectedAccountId={selectedAccountId}
          {...props}
        />
      )}
    </div>
  );
}

export default PendingView;
