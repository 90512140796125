import client from "../../../Configurations/apollo";
import { ApolloClient } from "@apollo/client";
import { InMemoryCache } from "@apollo/client";

import {
  CHANGE_ORDER_STATUS_TO_COMPLETED,
  CHANGE_ORDER_STATUS_TO_DELIVERY,
  CHANGE_ORDER_STATUS_TO_READY,
  CHANGE_TO_ACTIVE_STATUS,
  NOTIFICATION_STATUS,
  ORDER_NEW_UPLOAD,
  GET_ALL_NOTPROCESSED_SETTLEMENTS,
  GET_ALL_COMPLETED_SETTLEMENTS,
  GET_COMPLETEDPAYMENT_SEARCH,
  CHECK_SETTELEMENT_STATUS,
  UPDATE_SETTELEMENT_DETAILS,
  CREATE_TRANSFER,
  GET_SETTINGS,
  UPDATE_SETTINGS,
  UPDATE_DELIVERYBY,
  GET_LINKED_ACCOUNTS,
  ADD_LINKEDACCOUNT,
} from "./gqlQuery";

//
// Client for PUSH NOTIFICATION
const customClient = new ApolloClient({
  uri: `${process.env.REACT_APP_SCHEDULE_ALERT_URL}`,
  cache: new InMemoryCache(),
  defaultOptions: {
    watchQuery: {
      fetchPolicy: "no-cache",
      errorPolicy: "ignore",
    },
    query: {
      fetchPolicy: "no-cache",
      errorPolicy: "all",
    },
  },
});

const token = localStorage.getItem("token");

export const scheduleAlert = (params) => {
  return (dispatch) => {
    dispatch({
      type: "SCHEDULING_ALERT",
    });
    customClient
      .mutate({
        mutation: NOTIFICATION_STATUS,
        variables: {
          storeid: params.storeid,
          orderid: params.orderid,
          status: params.status,
          deliveryDate: `${params.deliveryDate}`,
          deliveryTime: `${params.deliveryTime}`,
          orderNumber: Number(params.orderNumber),
          shopassistantId: params.shopassistantId,
        },
      })
      .then((res) => {
        if (res) {
          dispatch({
            type: "SCHEDULING_SUCCESS",
            payload: res.data,
          });
        } else {
          dispatch({
            type: "SCHEDULING_DATA_FAILURE",
            payload: res.message,
          });
        }
      })
      .catch((err) => {
        dispatch({
          type: "SCHEDULING_FAILURE",
          payload: err.message,
        });
      });
  };
};
export const ChangetoActiveStatus = (params1, params2) => {
  return (dispatch) => {
    dispatch({
      type: "CHANGE_TO_ACTIVE",
    });
    client
      .mutate({
        mutation: CHANGE_TO_ACTIVE_STATUS,
        variables: {
          orderid: params1,
          preparationTime: Number(params2),
        },
      })
      .then((res) => {
        if (res) {
          dispatch({
            type: "CHANGE_TO_ACTIVE_STATUS_SUCCESS",
            payload: res.data,
          });
        } else {
          dispatch({
            type: "CHANGE_TO_ACTIVE_STATUS_FAILURE",
            payload: res.message,
          });
        }
      })
      .catch((err) => {
        dispatch({
          type: "CHANGE_TO_ACTIVE_STATUS_FAILURE",
          payload: err.message,
        });
      });
  };
};
export const ChangetoReadyStatus = (params1, params2) => {
  return (dispatch) => {
    dispatch({
      type: "CHANGE_TO_READY",
    });
    client
      .mutate({
        mutation: CHANGE_ORDER_STATUS_TO_READY,
        variables: {
          orderid: params1,
          products: params2,
        },
      })
      .then((res) => {
        if (res) {
          dispatch({
            type: "CHANGE_ORDER_STATUS_TO_READY_SUCCESS",
            payload: res.data,
          });
        } else {
          dispatch({
            type: "CHANGE_ORDER_STATUS_TO_READY_FAILURE",
            payload: res.message,
          });
        }
      })
      .catch((err) => {
        console.log(err);
        dispatch({
          type: "CHANGE_ORDER_STATUS_TO_READY_FAILURE",
          payload: err.message,
        });
      });
  };
};

export const changeToOutForDelivery = (params1, params2) => {
  return (dispatch) => {
    dispatch({
      type: "CHANGE_TO_DELIVERY",
    });
    client
      .mutate({
        mutation: CHANGE_ORDER_STATUS_TO_DELIVERY,
        variables: {
          orderid: params1,
          packingImages: params2,
        },
      })
      .then((res) => {
        if (res) {
          dispatch({
            type: "CHANGE_ORDER_STATUS_TO_DELIVERY_SUCCESS",
            payload: res.data,
          });
        } else {
          dispatch({
            type: "CHANGE_ORDER_STATUS_TO_DELIVERY_FAILURE",
            payload: res.message,
          });
        }
      })
      .catch((err) => {
        dispatch({
          type: "CHANGE_ORDER_STATUS_TO_DELIVERY_FAILURE",
          payload: err.message,
        });
      });
  };
};
export const ChangetoCompletedStatus = (params) => {
  return (dispatch) => {
    dispatch({
      type: "CHANGE_TO_COMPLETED",
    });
    client
      .mutate({
        mutation: CHANGE_ORDER_STATUS_TO_COMPLETED,
        variables: {
          orderid: params.orderid,
          stats: params.stats,
        },
      })
      .then((res) => {
        if (res) {
          dispatch({
            type: "CHANGE_ORDER_STATUS_TO_COMPLETED_SUCCESS",
            payload: res.data,
          });
        } else {
          dispatch({
            type: "CHANGE_ORDER_STATUS_TO_COMPLETED_FAILURE",
            payload: res.message,
          });
        }
      })
      .catch((err) => {
        dispatch({
          type: "CHANGE_ORDER_STATUS_TO_COMPLETED_FAILURE",
          payload: err.message,
        });
      });
  };
};
export const uploadOrderImage = (params) => {
  let Imgdata = params.files;
  return (dispatch) => {
    dispatch({
      type: "LOADING_ORDERIMAGE_UPLOAD",
    });
    client
      .mutate({
        mutation: ORDER_NEW_UPLOAD,
        variables: {
          file: Imgdata,
          type: 3,
          size: 512,
          filename: params.filename,
          orderid: localStorage.getItem("Rid"),
        },
      })
      .then((res) => {
        if (res.data) {
          // localStorage.clear('imageUrl')
          localStorage.setItem(
            "imageUrl",
            res.data &&
              res.data.imageEditAndUpload &&
              res.data.imageEditAndUpload.Location
          );
          dispatch({
            type: "ORDERIMAGE_UPLOAD_SUCCESS",
            payload: res.data,
          });
        } else {
          dispatch({
            type: "ORDERIMAGE_UPLOAD_FAILURE",
            payload: res.message,
          });
        }
      })
      .catch((err) => {
        dispatch({
          type: "ORDERIMAGE_UPLOAD_FAILURE",
          payload: err.message,
        });
      });
  };
};

export const uploadDisplayImage = (params) => {
  let Imgdata = params.files;
  return (dispatch) => {
    dispatch({
      type: "LOADING_DISPLAY_IMAGE_UPLOAD",
    });
    client
      .mutate({
        mutation: ORDER_NEW_UPLOAD,
        variables: {
          file: Imgdata,
          type: 3,
          size: 512,
          filename: params.filename,
          orderid: localStorage.getItem("Rid"),
        },
      })
      .then((res) => {
        if (res.data) {
          // localStorage.clear('imageUrl')
          localStorage.setItem(
            "displayimage",
            res.data &&
              res.data.imageEditAndUpload &&
              res.data.imageEditAndUpload.Location
          );
          dispatch({
            type: "DISPLAY_IMAGE_UPLOAD_SUCCESS",
            payload: res.data,
          });
        } else {
          dispatch({
            type: "DISPLAY_IMAGE_UPLOAD_FAILURE",
            payload: res.message,
          });
        }
      })
      .catch((err) => {
        dispatch({
          type: "DISPLAY_IMAGE_UPLOAD_FAILURE",
          payload: err.message,
        });
      });
  };
};

export const getAllNotProcessedSettlements = (params) => {
  return async (dispatch) => {
    function onSuccess(success) {
      dispatch({ type: "NOT_PROCESSED_SETTLEMENT_SUCCESS", payload: success });
      return success;
    }
    function onError(error) {
      dispatch({ type: "NOT_PROCESSED_SETTLEMENT_FAILED", payload: error });
      return error;
    }
    const success = await client.query({
      query: GET_ALL_NOTPROCESSED_SETTLEMENTS,
      variables: {
        pagination: { offset: params?.offset, first: params?.first },
        orderNumber: params?.orderNumber,
      },
    });
    if (success) {
      return onSuccess(success);
    } else if (success.errors) {
      return onError(success.errors[0]);
    }
  };
};

export const getAllCompletedSettlements = (params) => {
  return async (dispatch) => {
    dispatch({
      type: "COMPLETED_PAYMENT_STATUS",
    });
    function onSuccess(success) {
      dispatch({ type: "COMPLETED_SETTLEMENT_SUCCESS", payload: success });
      return success;
    }
    function onError(error) {
      dispatch({ type: "COMPLETED_SETTLEMENT_FAILED", payload: error });
      return error;
    }
    const success = await client.query({
      query: GET_ALL_COMPLETED_SETTLEMENTS,
      variables: {
        pagination: { offset: params?.offset, first: params?.first },
      },
    });
    if (success) {
      return onSuccess(success);
    } else if (success.errors) {
      return onError(success.errors[0]);
    }
  };
};
export const getCompletedPaymentSearch = (params) => {
  return async (dispatch) => {
    dispatch({
      type: "COMPLETED_PAYMENTSEARCH_STATUS",
    });
    function onSuccess(success) {
      dispatch({ type: "COMPLETED_PAYMENTSEARCH_SUCCESS", payload: success });
      return success;
    }
    function onError(error) {
      dispatch({ type: "COMPLETED_PAYMENTSEARCH_FAILED", payload: error });
      return error;
    }
    const success = await client.query({
      query: GET_COMPLETEDPAYMENT_SEARCH,
      variables: {
        pagination: { offset: 0, first: params?.first },
        dateFrom: params?.dateFrom,
        dateTo: params?.dateTo,
        orderNumber: params?.orderNumber,
      },
    });
    if (success) {
      return onSuccess(success);
    } else if (success.errors) {
      return onError(success.errors[0]);
    }
  };
};

export const checkSettlementStatus = (params) => {
  return (dispatch) => {
    dispatch({
      type: "CHECK_SETTELEMENT_STATUS",
    });
    client
      .mutate({
        mutation: CHECK_SETTELEMENT_STATUS,
        variables: {
          orderId: params?.orderId,
          forceCompleteSettlement: params?.forceCompleteSettlement,
        },
      })
      .then((res) => {
        if (res) {
          dispatch({
            type: "CHECK_SETTELEMENT_STATUS_SUCCESS",
            payload: res.data,
          });
        } else {
          dispatch({
            type: "CHECK_SETTELEMENT_STATUS_FAILURE",
            payload: res.message,
          });
        }
      })
      .catch((err) => {
        dispatch({
          type: "CHECK_SETTELEMENT_STATUS_FAILURE",
          payload: err.message,
        });
      });
  };
};

export const updateSettlementDetails = (params) => {
  return (dispatch) => {
    dispatch({
      type: "UPDATE_SETTELEMENT_STATUS",
    });
    client
      .mutate({
        mutation: UPDATE_SETTELEMENT_DETAILS,
        variables: {
          orderid: params.orderid,
          editedAmountForSeller: params?.editedAmountForSeller,
          editedAmountForNearshopz: params?.editedAmountForNearshopz,
          editedAmountForNearshopzDeliveryPerson:
            params?.editedAmountForNearshopzDeliveryPerson,
          editedAmountForExternalDeliveryPerson:
            params?.editedAmountForExternalDeliveryPerson,
          editedExtraAmountByNearshopz: params?.editedExtraAmountByNearshopz,
          settlementComment: params?.settlementComment,
          editedSellerReimbursement: params?.editedSellerReimbursement,
        },
      })
      .then((res) => {
        if (res) {
          dispatch({
            type: "UPDATE_SETTELEMENT_STATUS_SUCCESS",
            payload: res.data,
          });
        } else {
          dispatch({
            type: "UPDATE_SETTELEMENT_STATUS_FAILURE",
            payload: res.message,
          });
        }
      })
      .catch((err) => {
        dispatch({
          type: "UPDATE_SETTELEMENT_STATUS_FAILURE",
          payload: err.message,
        });
      });
  };
};

export const createTransfer = (params) => {
  return (dispatch) => {
    dispatch({
      type: "CREATE_TRANSFER_STATUS",
    });
    client
      .mutate({
        mutation: CREATE_TRANSFER,
        variables: {
          orderId: params?.orderId,
          transferTo: params?.transferTo,
          razorPayAccountId: params?.razorPayAccountId,
        },
      })
      .then((res) => {
        if (res) {
          dispatch({
            type: "CREATE_TRANSFER_STATUS_SUCCESS",
            payload: res.data,
          });
        } else {
          dispatch({
            type: "CREATE_TRANSFER_STATUS_FAILURE",
            payload: res.message,
          });
        }
      })
      .catch((err) => {
        dispatch({
          type: "CREATE_TRANSFER_FAILURE",
          payload: err.message,
        });
      });
  };
};

export const getSettings = () => {
  return async (dispatch) => {
    function onSuccess(success) {
      dispatch({ type: "GET_SETTINGS_SUCCESS", payload: success });
      return success;
    }
    function onError(error) {
      dispatch({ type: "GET_SETTINGS_FAILED", payload: error });
      return error;
    }
    const success = await client.query({
      query: GET_SETTINGS,
    });
    if (success) {
      return onSuccess(success);
    } else if (success.errors) {
      return onError(success.errors[0]);
    }
  };
};

export const updateAdminSettings = (params) => {
  console.log("Original params", params);

  // Convert all params to float
  const parsedParams = {
    defaultRestaurantProductMarkupPercent: parseFloat(
      params?.defaultRestaurantProductMarkupPercent
    ),
    defaultPackingMarkupPercent: parseFloat(
      params?.defaultPackingMarkupPercent
    ),
    defaultDeliveryChargeMarkupPercent: parseFloat(
      params?.defaultDeliveryChargeMarkupPercent
    ),
    defaultPlatformFeePercent: parseFloat(params?.defaultPlatformFeePercent),
    restaurantProductPriceGSTPercent: parseFloat(
      params?.restaurantProductPriceGSTPercent
    ),
    restaurantPackingChargeGSTPercent: parseFloat(
      params?.restaurantPackingChargeGSTPercent
    ),
    supermarketPackingChargeGSTPercent: parseFloat(
      params?.supermarketPackingChargeGSTPercent
    ),
    deliveryChargeGSTPercent: parseFloat(params?.deliveryChargeGSTPercent),
    platformFeeGSTPercent: parseFloat(params?.platformFeeGSTPercent),
  };

  console.log("Parsed params", parsedParams);
  return (dispatch) => {
    dispatch({
      type: "UPDATE_SETTINGS_LOADING",
    });
    client
      .mutate({
        mutation: UPDATE_SETTINGS,
        variables: {
          defaultRestaurantProductMarkupPercent:
            parsedParams?.defaultRestaurantProductMarkupPercent,
          defaultPackingMarkupPercent:
            parsedParams?.defaultPackingMarkupPercent,
          defaultDeliveryChargeMarkupPercent:
            parsedParams?.defaultDeliveryChargeMarkupPercent,
          defaultPlatformFeePercent: parsedParams?.defaultPlatformFeePercent,
          restaurantProductPriceGSTPercent:
            parsedParams?.restaurantProductPriceGSTPercent,
          restaurantPackingChargeGSTPercent:
            parsedParams?.restaurantPackingChargeGSTPercent,
          supermarketPackingChargeGSTPercent:
            parsedParams?.supermarketPackingChargeGSTPercent,
          deliveryChargeGSTPercent: parsedParams?.deliveryChargeGSTPercent,
          platformFeeGSTPercent: parsedParams?.platformFeeGSTPercent,
        },
      })
      .then((res) => {
        if (res) {
          dispatch({
            type: "UPDATE_SETTINGS_SUCCESS",
            payload: res.data,
          });
        } else {
          dispatch({
            type: "UPDATE_SETTINGS_FAILED",
            payload: res.message,
          });
        }
      })
      .catch((err) => {
        dispatch({
          type: "UPDATE_SETTINGS_FAILED",
          payload: err.message,
        });
      });
  };
};

export const updateDeliveryBy = (params) => {
  return (dispatch) => {
    dispatch({
      type: "UPDATE_DELIVERYBY_STATUS_LOADING",
    });
    client
      .mutate({
        mutation: UPDATE_DELIVERYBY,
        variables: {
          orderid: params?.orderid,
          deliveryBy: params?.deliveryBy,
        },
      })
      .then((res) => {
        if (res) {
          dispatch({
            type: "UPDATE_DELIVERYBY_STATUS_SUCCESS",
            payload: res.data,
          });
        } else {
          dispatch({
            type: "UPDATE_DELIVERYBY_STATUS_FAILED",
            payload: res.message,
          });
        }
      })
      .catch((err) => {
        dispatch({
          type: "UPDATE_DELIVERYBY_STATUS_FAILED",
          payload: err.message,
        });
      });
  };
};

export const getExternalDeliveryPersonLinkedAccounts = (params) => {
  return async (dispatch) => {
    dispatch({
      type: "GET_LINKED_ACCOUNTS_LOADING",
    });
    function onSuccess(success) {
      dispatch({ type: "GET_LINKED_ACCOUNTS_SUCCESS", payload: success });
      return success;
    }
    function onError(error) {
      dispatch({ type: "GET_LINKED_ACCOUNTS_FAILED", payload: error });
      return error;
    }
    const success = await client.query({
      query: GET_LINKED_ACCOUNTS,
      variables: {
        linkedAccountUser: params?.linkedAccountUser,
      },
    });
    if (success) {
      return onSuccess(success);
    } else if (success.errors) {
      return onError(success.errors[0]);
    }
  };
};

export const addAccount = (params) => {
  return (dispatch) => {
    dispatch({
      type: "ADD_ACCOUNT_LOADING",
    });
    client
      .mutate({
        mutation: ADD_LINKEDACCOUNT,
        variables: {
          email: params?.email,
          phone: params?.phone,
          legal_business_name: params?.legal_business_name,
          business_type: params?.business_type,
          razorPayAccountId: params?.razorPayAccountId,
          customer_facing_business_name: params?.customer_facing_business_name,
          profile: {
            category: params?.category,
            subcategory: params?.subcategory,
            addresses: {
              registered: {
                street1: params?.street1,
                street2: params?.street2,
                city: params?.city,
                state: params?.state,
                postal_code: params?.postal_code,
                country: params?.country,
              },
            },
          },
          linkedAccountUser: params?.linkedAccountUser,
        },
      })
      .then((res) => {
        if (res) {
          dispatch({
            type: "ADD_ACCOUNT_SUCCESS",
            payload: res.data,
          });
        } else {
          dispatch({
            type: "ADD_ACCOUNT_FAILED",
            payload: res.message,
          });
        }
      })
      .catch((err) => {
        dispatch({
          type: "ADD_ACCOUNT_FAILED",
          payload: err.message,
        });
      });
  };
};
